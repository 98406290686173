/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import {
  Box, Button, Flex, Image, Stack, Text, useMediaQuery,
} from '@chakra-ui/react';
import CandidateDetails from '@features/landingPage/components/assets/images/CandidateDetails.png';
import oneHundred from '@features/landingPage/components/assets/images/oneHundred.png';
import addUser from '@features/landingPage/components/assets/images/addUser.png';
import briefcase from '@features/landingPage/components/assets/images/briefcase.png';
import check from '@features/landingPage/components/assets/images/check.png';
import rocket from '@features/landingPage/components/assets/images/rocket.png';
import { useEffect, useState } from 'react';

export default function Home(props: { onGetStartedClick: () => void }) {
  const [showText1, setShowText1] = useState(false);
  const [showText2, setShowText2] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showOnScroll, setShowOnScroll] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowText1(true), 500);
    setTimeout(() => setShowText2(true), 500);
    setTimeout(() => setShowImages(true), 700);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('show-scroll');
      if (element?.getBoundingClientRect() && element?.getBoundingClientRect().top < window.innerHeight) {
        setShowOnScroll(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');
  return (
    <Flex
      height="100%"
      minWidth="100%"
      justifyContent="center"
      flexDirection={['column', 'column', 'column', 'row']}
    >
      <Stack>
        <Flex
          justifyContent="space-between"
          direction={{ base: 'column', md: 'row' }}
        >
          <Box
            mt="100px"
            ml={['10px', '20px', '20px', '360px', '240px', '40px']}
            maxWidth={{
              base: '100%', sm: '95%', md: '70%', lg: '50%', xl: '100%',
            }}
            height={{ base: 'auto', md: '132px' }}
            opacity={showText1 ? 1 : 0}
            transform={showText1 ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            flexBasis={{ base: '100%', md: 'auto' }}
            sx={{ flexShrink: 0 }}
          >
            <Text
              fontSize={{ base: '18px', md: '29px' }}
            >
              <strong> Simplify your testing process </strong> with TestMyCode
            </Text>
            <Text fontSize={{ base: '18px', md: '29px', lg: '29px' }}> user-friendly platform,
            </Text>
            <Text fontSize={{ base: '18px', md: '29px', lg: '29px' }}>
              designed <strong> to save you time and resources.</strong>
            </Text>
          </Box>

          <Box
            p={{ base: '0', md: '20px' }}
            maxWidth={{
              base: '100%', sm: '100%', md: '100%', lg: '70%', xl: '100%',
            }}
            ml={{
              base: '20px', md: '-400px', lg: '-500px', xl: '-500px',
            }}
            mr={['0', '0', '300px', '350px', '220', '3']}
            flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
            height={{ base: 'auto', md: '69px' }}
            mt={{ base: '20px', md: '290px', lg: '240px' }}
            opacity={showText2 ? 1 : 0}
            transform={showText2 ? 'translateX(0)' : 'translateX(50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            flexBasis={{ base: '100%', md: 'auto' }}
            order={{ base: '1', md: '2' }}
            sx={{ flexShrink: 0 }}
          >
            <Text
              fontSize={{ base: '12px', md: '15px' }}
              fontWeight="400"
            >We Designed Our Platform To Be As User-Friendly As Possible, With A
            </Text>
            <Text fontSize={{ base: '12px', md: '15px' }}>
              Range Of Customizable Tests And Assessment Tools That Are Quick And
            </Text>
            <Text fontSize={{ base: '12px', md: '15px' }}>
              Easy To Set Up.
            </Text>
            <Flex mt={6}>
              <Button
                fontSize="14px"
                fontWeight="400"
                color="white"
                bgColor="#176FFF"
                borderRadius="42px"
                width="168px"
                height="60px"
                onClick={props.onGetStartedClick}
              >Get Started
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Flex justifyContent="center" alignItems="center" transform={['rotate(-10deg)', 'rotate(0deg)']}>
          <Box mt={isSmallerThan768 ? '170' : '350'}>
            <Flex
              opacity={showImages ? 1 : 0}
              transform={showImages ? 'translateY(0)' : 'translateY(50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            >
              <Image
                src={rocket}
                ml={['-3', '10', '250', '470', '70', '-8']}
                maxWidth={['0%', '0%', '100%']}
              />
            </Flex>
          </Box>
          <Box
            position="relative"
            marginTop={isSmallerThan768 ? '20vw' : '150px'}
            minWidth={['10%', '20%', '70%', '50%', '80%']}
            mr={['400', '500', '720', '600', '700', '0']}
            ml={['-10', '-250', '150', '200', '650', '0']}
          >
            <Image
              src={CandidateDetails}
              maxWidth={['50%', 'auto', '95%']}
              ml={['1', '170', '150', '-50', '10']}
              mt={['0']}
            />
            <Flex
              position="absolute"
              top={-4}
              ml={['0', '-20', '110', '-70', '0']}
              opacity={showImages ? 1 : 0}
              transform={showImages ? 'translateY(0)' : 'translateY(50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            >
              <Image
                src={oneHundred}
                maxWidth={['50%', '100%']}
              />
            </Flex>
            <Flex
              position="absolute"
              top={isSmallerThan768 ? '100' : '200'}
              ml={['0', '-20', '100', '-70', '0']}
              opacity={showImages ? 1 : 0}
              transform={showImages ? 'translateY(0)' : 'translateY(50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            >
              <Image
                src={addUser}
                maxWidth={['50%', '100%']}
              />
            </Flex>
            <Flex
              position="absolute"
              top={isSmallerThan768 ? '-35' : '220'}
              right={['0', '700', '0', '110', '105', '110']}
              opacity={showImages ? 1 : 0}
              transform={showImages ? 'translateY(0)' : 'translateY(50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            >
              <Image src={check} />
            </Flex>
          </Box>

          <Flex
            mt={60}
            ml={{ base: '-800', md: '-200', lg: '-140' }}
            opacity={showImages ? 1 : 0}
            transform={showImages ? 'translateY(0)' : 'translateY(50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            <Image
              src={briefcase}
              maxWidth={['50%', '100%']}
            />
          </Flex>
        </Flex>

        <Flex
          alignItems={['flex-start', 'center']}
          flexDirection={['column', 'column', 'row', 'row', 'row']}
        >
          <Flex
            alignItems={['flex-start', 'center']}
            flexDirection={['column', 'column', 'row', 'row', 'row']}
            justifyContent="space-between"
            mt={isSmallerThan768 ? '40' : '20'}
            width={['100%', '90%', '30%']}
          >
            <Box
              id="show-scroll"
              alignItems={['flex-start', 'center']}
              opacity={showOnScroll ? 1 : 0}
              transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
              flexDirection={['column', 'column', 'row', 'row', 'row']}
            >
              <Text
                fontSize={[10, 20, 20, 30, 50]}
                fontWeight="500"
                color="#000000"
                mr={[90, -20, 1, 45, 45, 75]}
                ml={[120, 30, 140, 350, 240, 150]}
                mt={['0', '-20', '20', '0']}
              >120+
              </Text>
              <Text
                fontSize={[7, 20, 14, 20, 25]}
                fontWeight="400"
                color="#828A92"
                ml={[120, 30, 140, 350, 240, 150]}
              >Assessment
              </Text>
            </Box>
            <Box
              margin="0 auto"
              id="show-scroll"
              opacity={showOnScroll ? 1 : 0}
              transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
              flexDirection={['column', 'column', 'row', 'row', 'row']}
            >
              <Box
                ml={['4', '-7', '4', '30', '12', '12']}
                w={['300px', '450px', '1px', '1px']}
                h={['1px', '1px', '126px', '126px']}
                backgroundColor="#B6B6B6"
                mr={['0', '0', '4']}
                mt={['10', '5', '20', '0']}
                flexDirection={['column', 'row', 'row', 'row', 'row']}
              />
            </Box>
            <Box
              alignItems={['flex-start', 'center']}
              ml={12}
              id="show-scroll"
              opacity={showOnScroll ? 1 : 0}
              transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
              flexDirection={['column', 'column', 'row', 'row', 'row']}
            >
              <Text
                fontSize={[10, 20, 20, 30, 50]}
                fontWeight="500"
                color="#000000"
                ml={[70, -30, -7, 50, 10, 90]}
                mr={[60, 25, 35, 25]}
                mt={['10', '10', '20', '0']}
              >300+
              </Text>
              <Text
                fontSize={[7, 20, 14, 20, 25]}
                fontWeight="400"
                color="#828A92"
                ml={[70, -30, -7, 50, 10, 90]}
              >Questions
              </Text>
            </Box>
          </Flex>
          <Box
            ml={['5', '45', '170', '350', '350']}
            mt={['10', '40', '40', '20']}
            w={['300px', '450px', '1px', '1px']}
            h={['1px', '1px', '126px', '126px']}
            backgroundColor="#B6B6B6"
            mr={['0', '4', '10', '45', '4']}
            id="show-scroll"
            opacity={showOnScroll ? 1 : 0}
            transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            flexDirection={['column', 'column', 'row', 'row', 'row']}
          />
          <Box
            alignItems={['flex-start', 'center']}
            flexDirection={['column', 'column', 'row', 'row', 'row']}
            ml={8}
            mt={['10', '-95', '160', '20']}
            id="show-scroll"
            opacity={showOnScroll ? 1 : 0}
            transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            <Text
              fontSize={[10, 20, 20, 30, 50]}
              fontWeight="500"
              color="#000000"
              ml={['90', '-4', '-3', '-7', '50']}
              mr={55}
            >600+
            </Text>
            <Text
              fontSize={[7, 20, 14, 20, 25]}
              fontWeight="400"
              color="#828A92"
              ml={['90', '-4', '-3', '-7', '50']}
            >Candidate
            </Text>
          </Box>
          <Box
            // ml={12}
            ml={['2', '10', '10', '3', '12']}
            mt={['10', '-20', '160', '20']}
            w={['300px', '450px', '1px', '1px']}
            h={['1px', '1px', '126px', '126px']}
            flexDirection={['column', 'row', 'row', 'row', 'row']}
            backgroundColor="#B6B6B6"
            mr={4}
            id="show-scroll"
            opacity={showOnScroll ? 1 : 0}
            transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          />
          <Box
            alignItems={['flex-start', 'center']}
            ml={12}
            mt={['10', '40', '160', '20']}
            id="show-scroll"
            opacity={showOnScroll ? 1 : 0}
            transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            flexDirection={['column', 'column', 'row', 'row', 'row']}
          >
            <Text
              fontSize={[10, 20, 20, 30, 50]}
              fontWeight="500"
              color="#000000"
              ml={['79', '1', '-5', '-5', '50']}
              mr={['0', '100', '100', '90', '0']}
            >80+
            </Text>
            <Text
              fontSize={[7, 20, 14, 20, 25]}
              fontWeight="400"
              color="#828A92"
              ml={['79', '1', '-5', '-5', '50']}
            >Tests
            </Text>
          </Box>
        </Flex>
      </Stack>
    </Flex>
  );
}
