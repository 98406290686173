/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export function useFullScreenExit(callbackFn: (fullScreenExitCount: number) => void, openFullScreenModal: () => void) {
  const [fullScreenExitCount, setfullScreenExitCount] = useState(0);
  const handleExitFullscreen = () => {
    setfullScreenExitCount(fullScreenExitCount + 1);
    openFullScreenModal();
    callbackFn(fullScreenExitCount + 1);
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        handleExitFullscreen();
      }
    };
    const handleFullscreenError = () => {
      console.error('Fullscreen error occurred');
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('fullscreenerror', handleFullscreenError);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('fullscreenerror', handleFullscreenError);
    };
  }, [callbackFn, handleExitFullscreen]);
}
