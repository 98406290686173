import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import FullPageSpinner from '@components/FullPageSpinner';
import AuthenticationProvider from '@providers/authProvider';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '@lib/react-query';
import { Notifications } from '@components/Notifications/Notifications';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AxiosInterceptor } from '@lib/axios';
import theme from '@/theme';

type AppProviderProps = {
  children: React.ReactNode;
};

export default function AppProvider({ children }: AppProviderProps) {
  return (
    <ChakraProvider theme={theme}>
      <React.Suspense fallback={<FullPageSpinner />}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AuthenticationProvider>
              <AxiosInterceptor>
                <Notifications />
                {children}
              </AxiosInterceptor>
            </AuthenticationProvider>
          </QueryClientProvider>

        </Router>
      </React.Suspense>
    </ChakraProvider>
  );
}
