import { useRoutes } from 'react-router-dom';
import publicRoutes from '@routes/publicRoutes';
import { useIsAuthenticated } from 'react-auth-kit';
import protectedRoutes from '@routes/protectedRoutes';

export default function AppRoutes() {
  const isAuthenticated = useIsAuthenticated();
  const routes = isAuthenticated() ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes]);

  return <>{element}</>;
}
