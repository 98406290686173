export function getBrowserVisibilityProp() : string {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';// @ts-ignore
  } if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';// @ts-ignore
  } if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return '';
}

export function getBrowserDocumentHiddenProp() : string {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } // @ts-ignore
  if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } // @ts-ignore
  if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return '';
}

export function getIsDocumentHidden() {
  // @ts-ignore
  return !document[getBrowserDocumentHiddenProp()];
}
