import { MdMarkEmailRead } from 'react-icons/md';
import { Button, Flex, Text } from '@chakra-ui/react';
import { queryClient } from '@lib/react-query';
import { AssessmentsDetails } from '@features/candidateTest/types';
import AssessmentDetailsCard from '@features/candidateTest/components/AssessmentDetailsCard';
import { Link } from 'react-router-dom';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';
export default function AssessmentFinishedCard() {
  const submittedAssessments = queryClient.getQueryData(['submittedAssessmentsDetails']) as AssessmentsDetails;
  const { t } = useTranslation(nameSpaces.candidateInstructions);
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={5}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={5}
      >
        <MdMarkEmailRead
          color="#9747FF"
          size="55px"
        />
        <Text
          fontSize="xl"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {t('resultReceived')}
        </Text>
        <Text
          fontSize="sm"
          color="#818181"
        >
          {t('responsesSaved')}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={5}
      >
        {submittedAssessments.assessmentDetails.map((assessment) => (
          <AssessmentDetailsCard assessment={assessment} />))}
      </Flex>
      <Button
        variant="outline"
        colorScheme="purple"
        maxW="200px"
      >
        <Link to="https://gomycode.com/" target="_blank">GO MY CODE</Link>
      </Button>
    </Flex>
  );
}
