/* eslint-disable react/destructuring-assignment */
import {
  Box, Button, Flex, Image, Stack, Text, useMediaQuery,
} from '@chakra-ui/react';
import card from '@features/landingPage/components/assets/images/card.png';
import stars from '@features/landingPage/components/assets/images/stars.png';
import badge from '@features/landingPage/components/assets/images/badge.png';
import { useEffect, useState } from 'react';

export default function About(props: { onGetNextComponentClick: () => void }) {
  const [showOnScroll, setShowOnScroll] = useState(false);
  const [showTextOnScroll, setShowTextOnScroll] = useState(false);
  const [showImageOnScroll, setImageOnScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('show-scroll1');
      if (element?.getBoundingClientRect() && element?.getBoundingClientRect().top < window.innerHeight) {
        setTimeout(() => setShowOnScroll(true), 300);
        setTimeout(() => setShowTextOnScroll(true), 500);
        setTimeout(() => setImageOnScroll(true), 700);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');
  return (
    <Flex
      height="100%"
      flexDirection={isSmallerThan768 ? 'column' : 'row'}
      minWidth="100vw"
      justifyContent="center"
    >
      <Stack>
        <Flex justifyContent="space-between">
          <Box
            id="show-scroll1"
            mt={isSmallerThan768 ? '30px' : '160px'}
            ml={['10', '10', '120', '120']}
            height={{ base: 'auto', md: '132px' }}
            opacity={showOnScroll ? 1 : 0}
            transform={showOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            flexBasis={{ base: '100%', md: 'auto' }}
          >
            <Text fontSize={['sm', 'md', 'lg', 'xl']} fontWeight="600" color="#000000">We Understand That The Testing Process Can </Text>
            <Text fontSize={['sm', 'md', 'lg', 'xl']} fontWeight="600" color="#000000">Be Time-Consuming And Resource-Intensive.</Text>
          </Box>
          <Box
            id="show-scroll1"
            flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
            p={{ base: '0', md: '20px' }}
            maxWidth={{
              base: '70%', md: '50%', lg: '70%', xl: '100%',
            }}
            height={{ base: 'auto', md: '69px' }}
            ml={['-20', '']}
            mt={['260', '170', '230', '220', '150', '250', '200']}
            opacity={showOnScroll ? 1 : 0}
            transform={showOnScroll ? 'translateX(0)' : 'translateX(50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            <Text fontSize={isSmallerThan768 ? '12px' : '13px'} fontWeight="400">
              We Designed Our Platform To Be As User-Friendly As Possible, With A
            </Text>
            <Text fontSize={isSmallerThan768 ? '12px' : '13px'} fontWeight="400">
              Range Of Customizable Tests And Assessment Tools That Are Quick And
            </Text>
            <Text fontSize={isSmallerThan768 ? '12px' : '13px'} fontWeight="400" mb={8}>Easy To Set Up.</Text>
            <Flex mt={-5}>
              <Button
                fontSize="14px"
                fontWeight="400"
                color="white"
                bgColor="#176FFF"
                borderRadius="42px"
                width="168px"
                height="60px"
                ml={isSmallerThan768 ? '-7px' : '0'}
                onClick={props.onGetNextComponentClick}
              >Learn More
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Box
          id="show-scroll1"
          opacity={showTextOnScroll ? 1 : 0}
          transform={showTextOnScroll ? 'translateY(0)' : 'translateY(70px)'}
          transition="opacity 0.5s ease-out, transform 0.5s ease-out"
        >
          <Flex justifyContent="center" flexDirection="column" alignItems="center">
            <Text
              fontSize={['18', '25', '31']}
              color="#000000"
              mt={isSmallerThan768 ? '160' : '220'}
              maxWidth={['100%', '80%', '100%']}
              ml={['10', '10', '50', '-50']}
            >
              Our Assessments Help You Hire <strong>Smarter, Not Harder.</strong>
            </Text>
            <Text
              fontSize={['9', '11', '13']}
              color="#6B6B6B"
              maxWidth={['100%', '80%', '100%']}
              ml={['10', '10', '50', '-50']}
              mt={{ base: '10px', md: '' }}
            >
              We Understand That The Testing Process Can Be Time-Consuming And Resource-Intensive.
              That&apos;s Why We&apos;ve Designed Our Platform
            </Text>
            <Text
              fontSize={['9', '11', '13']}
              color="#6B6B6B"
              maxWidth={['100%', '80%', '100%']}
              ml={['10', '10', '50', '-50']}
            >
              To Be As User-Friendly As Possible,
              With A Range Of Customizable Tests And Assessment Tools That Are Quick And Easy To Set Up.
            </Text>
          </Flex>
        </Box>
        <Flex
          alignItems={['flex-start', 'center']}
          flexDirection={['column', 'column', 'row', 'row', 'row']}
        >
          <Flex
            alignItems={['flex-start', 'center']}
            flexDirection={['column', 'column', 'row', 'row', 'row']}
            justifyContent="space-between"
            mt={isSmallerThan768 ? '49' : '20'}
            ml={isSmallerThan768 ? '0' : '-10'}
          >
            <Box
              textAlign="center"
              id="show-scroll1"
              opacity={showImageOnScroll ? 1 : 0}
              transform={showImageOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            >
              <Flex alignItems="center" justifyContent="center" mb={6} mr={20} ml={['20', '20', '100', '20', '50', '0']}>
                <Image src={stars} />
              </Flex>
              <Text
                fontSize={['14', '12', '11', '16']}
                fontWeight="500"
                color="#000000"
                mr={20}
                ml={['20', '20', '100', '20', '50', '0']}
                maxWidth={['100%', '80%', '100%']}
              >
                Discover top talent with our
              </Text>
              <Text fontSize={['14', '12', '11', '16']} fontWeight="500" color="#000000" mr={20} ml={['20', '20', '100', '20', '50', '0']}>
                personalized assessment.
              </Text>
            </Box>
            <Box
              margin="0 auto"
              id="show-scroll1"
              opacity={showImageOnScroll ? 1 : 0}
              transform={showImageOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            >
              <Box
                ml={['8', '0', '50', '12']}
                w={['300px', '300px', '1px']}
                h={['1px', '1px', '126px']}
                backgroundColor="#B6B6B6"
                mr={4}
                mt={['10', '10', '70', '0']}
              />
            </Box>
            <Box
              alignItems="center"
              ml={['12', '700', '12']}
              id="show-scroll1"
              opacity={showImageOnScroll ? 1 : 0}
              transform={showImageOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
              mt={['10', '10', '3', '0']}
            >
              <Flex alignItems="center" justifyContent="center" mb={6} mr={20} ml={['7', '-630', '50', '50', '20']}>
                <Image src={badge} />
              </Flex>
              <Text
                fontSize={['14', '12', '11', '16']}
                fontWeight="500"
                color="#000000"
                ml={['10', '-430', '50', '50', '20']}
                mr={20}
              >
                Assess with confidence and
              </Text>
              <Text fontSize={['14', '12', '11', '16']} fontWeight="500" color="#000000" ml={['10', '-430', '50', '50', '20']}>
                build your dream team.
              </Text>
            </Box>
          </Flex>
          <Box
            ml={['12', '1', '20', '12']}
            mt={['10', '10', '40', '20']}
            w={['300px', '300px', '1px']}
            h={['1px', '1px', '126px']}
            backgroundColor="#B6B6B6"
            mr={4}
            id="show-scroll1"
            opacity={showImageOnScroll ? 1 : 0}
            transform={showImageOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          />
          <Box
            alignItems="center"
            ml={12}
            mt={['10', '10', '20', '20']}
            id="show-scroll2"
            opacity={showImageOnScroll ? 1 : 0}
            transform={showImageOnScroll ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            <Flex alignItems="center" justifyContent="center" mb={6} ml={['2', '-90', '-5', '-70', '20']}>
              <Image src={card} />
            </Flex>
            <Text fontSize={['14', '12', '11', '16']} fontWeight="500" color="#000000" ml={['5', '-30', '-5', '50', '20']}>
              Make data-driven hiring decisions
            </Text>
            <Text fontSize={['14', '12', '11', '16']} fontWeight="500" color="#000000" ml={['5', '-30', '-5', '50', '20']}>
              with our assessment tools.
            </Text>
          </Box>
        </Flex>
      </Stack>
    </Flex>
  );
}
