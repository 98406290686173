/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import '@assets/public/hubspot.css';

declare const hbspt: any;
export default function HubSpotForm() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        if (typeof hbspt !== 'undefined') {
          hbspt.forms.create({
            region: 'na1',
            portalId: '6957122',
            formId: '289518d1-3726-4455-8d4d-64e7addff3e6',
            target: '#hubspot-form',
            locale: 'en',
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const label = document.querySelector('#hubspot-form label[for="lastname-289518d1-3726-4455-8d4d-64e7addff3e6"]');
    if (label) {
      label.textContent = 'test';
    }
  }, []);
  return (
    <Flex height="100%">
      <Stack>
        <Flex id="hubspot-form" mt={-300} justifyContent="center" alignItems="flex-end" />
      </Stack>
    </Flex>
  );
}
