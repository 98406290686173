import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Flex } from '@chakra-ui/react';
import { RefObject, useEffect, useState } from 'react';
import { useUTCTimeNow } from '../../api/getUTCTimeNow';

type TimerProps = {
  expiryTimestamp: Date;
  formRef: RefObject<HTMLFormElement>;
  onTimeUp: () => void;
};

export default function Timer({ formRef, expiryTimestamp, onTimeUp }: TimerProps) {
  const [duration, setDuration] = useState<number>(0);
  const [showModalManually, setShowModalManually] = useState(false);
  const utcTimeNow = useUTCTimeNow();
  const utcTimeNowData = utcTimeNow.data!;

  useEffect(() => {
    if (utcTimeNowData) {
      const currentDate = new Date(utcTimeNowData);
      const timeDifferenceInSeconds = Math.floor((new Date(expiryTimestamp).getTime() - currentDate.getTime()) / 1000);
      setDuration(timeDifferenceInSeconds > 0 ? timeDifferenceInSeconds : 0);
    }
  }, [expiryTimestamp, utcTimeNowData]);
  const children = ({ remainingTime }: { remainingTime: number }) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = Math.floor(remainingTime % 60);
    return (
      <Flex
        rounded="full"
        bg="#EDF9F1"
        width="60%"
        height="60%"
        justifyContent="center"
        alignItems="center"
        color="#4CCD59"
        fontWeight="bold"
      >
        {`${minutes} : ${seconds}`}
      </Flex>
    );
  };

  return (
    <CountdownCircleTimer
      isPlaying
      duration={duration}
      colors={['#4CCD59', '#FDA04B', '#E55353']}
      colorsTime={[7, 5, 2]}
      onComplete={() => {
        if (!showModalManually && formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
          onTimeUp();
        }
      }}
      size={110}
      strokeWidth={10}
    >
      {children}
    </CountdownCircleTimer>
  );
}
