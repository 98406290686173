import React from 'react';
import CustomErrorPage from './CustomErrorPage';

export default function TestCanceled() {
  return (
    <CustomErrorPage
      title="Test was canceled"
      description="The test was canceled by the hiring manager.
        If you think this is an error, please contact the hiring manager.
        Thank you for your time."
    />
  );
}
