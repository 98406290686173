import { useParams } from 'react-router-dom';
import './styles.css';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import axios from '@/lib/axios';
import { useFullScreenExit } from '@/hooks/useFullScreenExit';
import ExitFullScreenModal from '@/features/candidateTest/components/ExitFullScreenModal';

export default function CountdownTimer() {
  const { UniqueTestId } = useParams();
  const queryClient = useQueryClient();

  const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState(false);

  const openFullScreenModal = () => {
    setIsFullScreenModalOpen(true);
  };
  const resumeFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      try {
        document.documentElement.requestFullscreen();
      } catch (error) {
        //
      }
    } else {
      //
    }
  };
  const alertOnFullScreenExit = () => queryClient.fetchQuery('alertOnFullScreenExit', () => axios
    .post(`/CandidateTest/MouseLeaveDetected/${UniqueTestId}`));
  useFullScreenExit(alertOnFullScreenExit, openFullScreenModal);

  return (
    <div className="countdown">
      <svg viewBox="-50 -50 100 100" strokeWidth="7">
        <circle r="45" />

        <circle r="45" strokeDasharray="282.7433388230814" strokeDashoffset="282.7433388230814px" />
      </svg>
      <ExitFullScreenModal
        isOpen={isFullScreenModalOpen}
        onClose={() => setIsFullScreenModalOpen(false)}
        onConfirm={() => setIsFullScreenModalOpen(false)}
        onResume={resumeFullScreen}
      />
    </div>
  );
}
