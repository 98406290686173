import { Button } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Icon } from '@chakra-ui/icons';
import { BsChevronRight } from 'react-icons/bs';

type PropTypes = {
  label: string;
  variant: 'solidIcon' | 'outlinePrevious' | 'solid' | 'ghost' ;
  color?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  [key: string]: any;
};

export default function CustomButton({
  label, variant, color, onClick, isDisabled, ...rest
}: PropTypes) {
  const SelectVariant = useCallback((v: string) => {
    switch (v) {
      case 'solidIcon':
        return 'solid';
      case 'outlinePrevious':
        return 'outline';
      case 'solid':
        return 'solid';
      case 'ghost':
        return 'ghost';
      default:
        return 'filled';
    }
  }, []);
  const rightIcon = variant === 'solidIcon' ? {
    rightIcon: <Icon as={BsChevronRight} />,
  } : {};
  return (
    <Button
      variant={SelectVariant(variant)}
      minW="200px"
      /* eslint-disable-next-line no-nested-ternary */
      bgColor={SelectVariant(variant) === 'solid' ? color : SelectVariant(variant) === 'ghost' ? '#F9F3FF' : 'white'}
      color={SelectVariant(variant) === 'solid' ? 'white' : color}
      outline={variant === 'outlinePrevious' ? '1px solid #A55AFF' : 'none'}
      onClick={onClick}
      paddingX="4rem"
      paddingY="1.5rem"
      fontWeight="normal"
      disabled={isDisabled}
      opacity={isDisabled ? 0 : 1}
      {...rightIcon}
      {...rest}
    >
      {label}
    </Button>

  );
}

CustomButton.defaultProps = {
  color: '#A55AFF',
  onClick: () => {},
  isDisabled: false,
};
