import {
  MdOutlineAssessment, MdOutlineDashboardCustomize, MdPeople, MdBusiness,
} from 'react-icons/md';
import { VscLibrary } from 'react-icons/vsc';

export default [
  {
    label: 'SuperAdmin Dashboard',
    icon: MdOutlineDashboardCustomize,
    href: '/app/campanies/dashboard',
  },
  {
    label: 'Company Owner Dashboard',
    icon: MdOutlineDashboardCustomize,
    href: `/app/campanies/companyDashboard/${localStorage.getItem('companyId') || ''}`,
  },
  {
    label: 'Assessment Library',
    icon: VscLibrary,
    href: '/app/assessments',
  },
  {
    label: 'Company Tests',
    icon: MdOutlineAssessment,
    href: `/app/campanies/tests/${localStorage.getItem('companyId') || ''}`,
  },
  {
    label: 'Tests',
    icon: MdOutlineAssessment,
    href: '/app/tests',
  },
  {
    label: 'Users',
    icon: MdPeople,
    href: '/app/dashboard/admin',
  },
  // {
  //   label: 'Hired Candidates',
  //   icon: MdBusiness,
  //   href: '/app/dashboard/hired',
  // },
  {
    label: 'Hired',
    icon: MdBusiness,
    href: '/app/dashboard/hiredCandidates',
  },
  {
    label: 'Companies',
    icon: MdBusiness,
    href: '/app/campanies/list',
  },
  {
    label: 'SuperAdmins',
    icon: MdPeople,
    href: '/app/campanies/superAdmins',
  },
  {
    label: 'Hiring Managers',
    icon: MdPeople,
    href: '/app/campanies/hiringManagers',
  },
  // // {
  // label: 'Roles',
  // icon: MdPeople,
  // href: '/app/roles',
  // },
];
