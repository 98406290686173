import { Spinner } from '@chakra-ui/react';
import React from 'react';
import styles from './styles.module.css';

export default function FullPageSpinner() {
  return (
    <>
      <Spinner
        className={styles.spinner}
        thickness="10px"
        speed="0.8s"
        emptyColor="gray.200"
        color="#0061FF"
        size="xl"
      />
    </>

  );
}
