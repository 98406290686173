import { Flex } from '@chakra-ui/react';
import StartTestCandidateMain from '@features/candidateTest/components/StartTestCandidateMain';
import { useCurrentAssessment } from '@features/candidateTest/api/getCurrentAssessment';
import FullPageSpinner from '@components/FullPageSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import { createContext, useEffect, useState } from 'react';
import { AssessmentDTO } from '@features/candidateTest/types';
import StartedTestHeader from '@features/candidateTest/components/StartedTestHeader';
import AssessmentCompleted from '@features/candidateTest/pages/AssessmentCompleted';
import { useTestAndCandidate } from '@features/candidateTest/api/getTestAndCandidate';
import axios from 'axios';
import { Mixpanel } from '@/mixpanel';
import { useUTCTimeNow } from '../api/getUTCTimeNow';

export const CurrentAssessmentContext = createContext<AssessmentDTO>({} as AssessmentDTO);
export default function StartedTestPage() {
  const { UniqueTestId } = useParams();
  const [assessmentBridgePage, setAssessmentBridgePage] = useState(false);
  const [endDate, setEndDate] = useState<any | null>(null);
  const navigate = useNavigate();
  const assignmentDataQuery = useTestAndCandidate({ UniqueTestId: UniqueTestId! });
  const utcTimeNow = useUTCTimeNow();
  const utcTimeNowData = utcTimeNow?.data!;
  const currentAssessmentQuery = useCurrentAssessment({
    UniqueTestId: UniqueTestId!,
    config: {
      cacheTime: 1000 * 60 * 60,
      onSuccess: (data) => {
          const startDate = new Date(data.startedAt);
          const endDate = new Date(startDate.getTime() + (data?.duration * 1000))
          setEndDate(endDate);
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.data === 'TestExpired') {
            navigate('/testExpired');
          }
        }
      },
    },
  });

  useEffect(() => {
    const currentTime = new Date(utcTimeNowData!)?.getTime();
    if (currentTime > endDate!) {
      Mixpanel.track('Test Timed Out', { TestTitle: assignmentDataQuery?.data?.assignedTest?.test?.title, testId: UniqueTestId });
      Mixpanel.track('Test Started', { TestTitle: assignmentDataQuery?.data?.assignedTest?.isStarted });
    }
  }, [endDate, navigate, UniqueTestId, assignmentDataQuery?.data?.assignedTest?.test?.title, assignmentDataQuery?.data?.assignedTest?.isStarted]);

  if (currentAssessmentQuery.isFetching || assignmentDataQuery.isFetching) {
    return (<FullPageSpinner />);
  }
  if (!currentAssessmentQuery.data) {
    navigate('/404');
  }
  return (
    <CurrentAssessmentContext.Provider value={currentAssessmentQuery.data!}>
      <Flex
        justifyContent="center"
        alignItems="center"
      >
        <StartedTestHeader assessmentBridgePage={assessmentBridgePage} />
        {!assessmentBridgePage
          ? <StartTestCandidateMain assessmentEndDate={endDate} setAssessmentBridgePage={setAssessmentBridgePage} serverSideDate={utcTimeNowData!}/>
          : (<AssessmentCompleted setAssessmentBridgePage={setAssessmentBridgePage} />
          )}
      </Flex>
    </CurrentAssessmentContext.Provider>
  );
}
