/* eslint-disable react-hooks/rules-of-hooks */
import {
  Flex, Link, Stack, Text, useColorModeValue,
} from '@chakra-ui/react';
import { NavItem } from '@features/Navigation/types/NavItem';
import { ROLES } from '@features/profile/types/User';
import { Icon } from '@chakra-ui/icons';
import { useAuthorization } from '@/lib/authorization';

export default function MobileNavItem({ label, icon, href }: NavItem) {
  const { checkAccess } = useAuthorization();
  const hasCompanyId = !!localStorage.getItem('companyId');
  const isSuperAdmin = checkAccess({ allowedRoles: [ROLES.SUPERADMIN] });
  const isCompanyOwner = checkAccess({ allowedRoles: [ROLES.COMPANYOWNER] });
  const isHiringManager = checkAccess({ allowedRoles: [ROLES.HIRINGMANAGER] });

  if (label === 'Users' && !checkAccess({ allowedRoles: [ROLES.ADMIN] })) {
    return null;
  }
  // if (label === 'Hired Candidates' && !checkAccess({ allowedRoles: [ROLES.HIRINGMANAGER] })) {
  //   return null;
  // }
  if (label === 'Hired' && !checkAccess({ allowedRoles: [ROLES.ADMIN] })) {
    return null;
  }
  if (label === 'Companies') {
    if (hasCompanyId) {
      return null;
    }
    if (!isSuperAdmin) {
      return null;
    }
  }
  // if (label === 'Roles' && !checkAccess({ allowedRoles: [ROLES.SUPERADMIN] })) {
  // return null;
  // }
  // if (label === 'Dashboard' && !checkAccess({ allowedRoles: [ROLES.ADMIN, ROLES.HIRINGMANAGER] })) {
  //   return null;
  // }
  if (label === 'SuperAdmin Dashboard') {
    if (isSuperAdmin && hasCompanyId) {
      return null;
    }
    if (!isSuperAdmin) {
      return null;
    }
  }

  if (label === 'SuperAdmins') {
    if (hasCompanyId) {
      return null;
    }
    if (!isSuperAdmin) {
      return null;
    }
  }
  if (label === 'Company Owner Dashboard') {
    if (!(isCompanyOwner || (isSuperAdmin && hasCompanyId))) {
      return null;
    }
  }
  if (label === 'Company Tests') {
    if (!(isCompanyOwner || (isSuperAdmin && hasCompanyId) || isHiringManager)) {
      return null;
    }
  }
  if (label === 'Tests') {
    if (hasCompanyId) {
      return null;
    }
    if (!isSuperAdmin) {
      return null;
    }
  }
  if (label === 'Assessment Library') {
    if (hasCompanyId) {
      return null;
    }
    if (!isSuperAdmin) {
      return null;
    }
  }
  if (label === 'Hiring Managers') {
    if (!(isCompanyOwner || (isSuperAdmin && hasCompanyId))) {
      return null;
    }
  }
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Icon as={icon} height="20px" width="20px" />
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>

      </Flex>

    </Stack>
  );
}
