/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Navbar from '@/features/landingPage/components/Navbar';
import Home from './Home';
import About from './About';
import Tests from './Tests';
import Candidates from './Candidates';
// import Testimonials from './Testimonials';
import BookDemo from './BookDemo';
// import HubSpotForm from './HubspotForm';
// import Footer from './Footer';

export default function LandingPage() {
  const [activeComponent, setActiveComponent] = useState('Home');
  const handleNavClick = (component: string) => {
    setActiveComponent(component);
  };
  const scrollToNextSection = () => {
    const nextSection = document.getElementById('about');
    nextSection?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToTests = () => {
    const nextSection = document.getElementById('test');
    nextSection?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToCandidates = () => {
    const nextSection = document.getElementById('candidate');
    nextSection?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToDemo = () => {
    const nextSection = document.getElementById('demo');
    nextSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Stack overflow="hidden">
      <Navbar onNavClick={handleNavClick} activeComponent={activeComponent} />
      <Box id="home">
        <Home onGetStartedClick={scrollToNextSection} />
      </Box>
      <Box id="about">
        <About onGetNextComponentClick={scrollToTests} />
      </Box>
      <Box id="test">
        <Tests onGetNextComponentClick={scrollToCandidates} />
      </Box>
      <Box id="candidate">
        <Candidates onGetNextComponentClick={scrollToDemo} />
      </Box>
      <Box id="demo">
        <BookDemo />
      </Box>
      {/* <Box>
        <HubSpotForm />
      </Box> */}
    </Stack>
  );
}
