import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import nameSpaces from './nameSpaces';
import enCandidateInstructions from './en/candidateInstructions.json';

import frCandidateInstructions from './fr/candidateInstructions.json';
i18next.use(initReactI18next).init({
    debug: true,
    lng: 'en',
    resources: {
        en: {
           [nameSpaces.candidateInstructions] : enCandidateInstructions, 
        },
        fr: {
            [nameSpaces.candidateInstructions] : frCandidateInstructions
        }
    }
})