import mixpanel from 'mixpanel-browser';

const environment = process.env.NODE_ENV || 'development';

const mixpanelTokens: any = {
  development: 'c364abb70831a804e1285a9669b56676',
  preprod: 'a60fdfe7b4553a1915b7403d7ef32c50',
  production: '7f54e3f5e3db8431bf0f3e1a7d7c8f8e',
};

mixpanel.init(mixpanelTokens[environment]);

const actions = {
  identify: (id: any) => {
    mixpanel.identify(id);
  },
  alias: (id: any) => {
    mixpanel.alias(id);
  },
  track: (name: any, props: any) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
