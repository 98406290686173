/* eslint-disable react/destructuring-assignment */
import {
  Flex, VisuallyHiddenInput, Text, Box,
} from '@chakra-ui/react';
import {
  Dispatch, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSubmitAssessmentAnswers } from '@features/candidateTest/api/submitAssessmentAnswers';
import { AssessmentAnswersDTO } from '@features/candidateTest/types';
import { usePageIsHidden } from '@hooks/usePageVisibility';
import { usePostAlertOnPageChange } from '@features/candidateTest/api/usePostAlertOnPageChange';
import { CurrentAssessmentContext } from '@features/candidateTest/pages/StartedTestPage';
import QuestionWrapper from '@features/candidateTest/components/Questions/QuestionWrapper';
import TwoButtonGroup from '@features/candidateTest/components/Buttons/TwoButtonGroup';
import Timer from '@features/candidateTest/components/Timer';
import { useQueryClient } from 'react-query';
import axios from '@lib/axios';
import { useCopyPaste } from '@/hooks/useCopyPaste';
import CopyPasteModal from './CopyPasteModal';
import { useFullScreenExit } from '@/hooks/useFullScreenExit';
import ExitFullScreenModal from './ExitFullScreenModal';
import { Mixpanel } from '@/mixpanel';

type PropTypes = {
  assessmentEndDate: Date | null;
  setAssessmentBridgePage: Dispatch<boolean>;
  serverSideDate: string;
};
export default function StartTestCandidateMain({ assessmentEndDate, setAssessmentBridgePage, serverSideDate } : PropTypes) {
  const { UniqueTestId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState(false);

  const resumeFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      try {
        document.documentElement.requestFullscreen();
      } catch (error) {
        //
      }
    } else {
      //
    }
  };
  const isHidden = usePageIsHidden();
  const queryClient = useQueryClient();
  const formRef = useRef<HTMLFormElement>(null);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const openFullScreenModal = () => {
    setIsFullScreenModalOpen(true);
  };

  const alertOnFullScreenExit = () => queryClient.fetchQuery('alertOnFullScreenExit', () => axios
    .post(`/CandidateTest/MouseLeaveDetected/${UniqueTestId}`));
  useFullScreenExit(alertOnFullScreenExit, openFullScreenModal);
  const alertOnCopyPaste = () => queryClient.fetchQuery('alertOnCopyPaste', () => axios.post(`/CandidateTest/copy-paste-detection/${UniqueTestId}`));
  useCopyPaste(alertOnCopyPaste, openModal);
  const usePostAlertOnPageChangeMutation = usePostAlertOnPageChange({ UniqueTestId, wasHidden: isHidden });
  useEffect(() => {
    if (isHidden) {
      usePostAlertOnPageChangeMutation.mutate('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden]);
  const serverDateTime = new Date(serverSideDate);
  const useSubmitAssessmentAnswersMutation = useSubmitAssessmentAnswers();
  const formMethods = useForm<AssessmentAnswersDTO>();
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const currentAssessment = useContext(CurrentAssessmentContext);
  const timeLeftt = Math.max(0, Math.floor(new Date(assessmentEndDate!).getTime() - serverDateTime.getTime()));


  const onSubmit = useCallback(async (data: AssessmentAnswersDTO) => {
    const updatedAnswers = data.answers.map((answer) => {
      if (answer.questionType === 'OEQ') {
        return {
          ...answer,
          answerIds: answer.answerIds ? [...answer.answerIds, ''] : [''],
        };
      }
      return answer;
    });
  
    if (timeLeftt > 0) {
      await useSubmitAssessmentAnswersMutation.mutateAsync({...data, autoSave: true});
      setCurrentQuestionIdx(0);
      formMethods.reset();
      setAssessmentBridgePage(true);
      Mixpanel.track('Test Submitted', { testId: UniqueTestId });
    }
  }, [timeLeftt]);
  useEffect(() => {
    if (String(currentAssessment)?.includes('has expired')) {
      setIsExpired(true)
    }
    const nextAssessmentIdx = currentQuestionIdx + 1;
    if ((timeLeftt === 0 && nextAssessmentIdx <= currentAssessment?.questions?.length) ?? isExpired) {
      setCurrentQuestionIdx(currentAssessment?.questions?.length);
      setAssessmentBridgePage(true);
      setCurrentQuestionIdx(0);
    }
  }, [timeLeftt, formMethods, currentQuestionIdx, currentAssessment?.questions?.length, setAssessmentBridgePage, isExpired]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} ref={formRef}>
        <VisuallyHiddenInput value={UniqueTestId} {...formMethods.register('uniqueTestId')} />
        <VisuallyHiddenInput value={currentAssessment?.id} {...formMethods.register('assignedAssessmentId')} />
        <Flex
          id="fullscreen-button"
          flexDirection="column"
          bg={currentAssessment?.questions[currentQuestionIdx]?.type === 'Coding' ? '#101012' : 'white'}
          minH="80vh"
          marginX={10}
          rounded="30px"
          paddingY="30px"
          paddingX={20}
          minW="90vw"
        >
          <Box
            fontWeight="bold"
            alignSelf="flex-end"
          >
            <Text fontWeight="bold" as="span">Q{currentQuestionIdx + 1}</Text>
            <Text color="#A5A5A5" as="span" fontWeight=""> /{currentAssessment?.questions?.length} </Text>
          </Box>

          {currentAssessment?.questions?.map((question, index) => (
            <QuestionWrapper
              question={question}
              index={index}
              currentQuestionIdx={currentQuestionIdx}
              key={question?.id}
              templates={question?.templates}
              visibleTestCases={question?.visibleTestCases}
            />
          ))}
          <TwoButtonGroup
            isLoading={useSubmitAssessmentAnswersMutation.isLoading}
            setCurrentQuestionIdx={setCurrentQuestionIdx}
            currentQuestionIdx={currentQuestionIdx}
            questionsArrayLength={currentAssessment?.questions?.length}
            alignSelf="flex-end"
          />
          <Timer
            key={currentAssessment?.order}
            expiryTimestamp={assessmentEndDate!}
            formRef={formRef}
            onTimeUp={async () => {
              if (timeLeftt === 0) {
                await onSubmit(formMethods.getValues());
              }
            }}
          />
        </Flex>
        {isModalOpen && (
        <CopyPasteModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
          }}
        />
        )}

        <ExitFullScreenModal
          isOpen={isFullScreenModalOpen}
          onClose={() => setIsFullScreenModalOpen(false)}
          onConfirm={() => setIsFullScreenModalOpen(false)}
          onResume={resumeFullScreen}
        />
      </form>

    </FormProvider>
  );
}
