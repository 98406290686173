import MainLayout from '@components/Layout/MainLayout';
import React, { Suspense } from 'react';
import FullPageSpinner from '@components/FullPageSpinner';
import { Navigate, Outlet } from 'react-router-dom';

const TestDesignRoutes = React.lazy(() => import('@features/tests/routes'));
const AssessmentDesignRoutes = React.lazy(() => import('@features/assessments/routes'));
const ProfileRoutes = React.lazy(() => import('@features/profile/routes'));
const DashboardRoutes = React.lazy(() => import('@features/dashboard/routes'));
const CampaniesRoutes = React.lazy(() => import('@features/campanies/routes'));
// const RolesRoutes = React.lazy(() => import ('@features/roles/routes'));

function App() {
  return (
    <MainLayout>
      <Suspense
        fallback={(
          <FullPageSpinner />
        )}
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
}

export default [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'assessments/*', element: <AssessmentDesignRoutes /> },
      { path: 'dashboard/*', element: <DashboardRoutes /> },
      { path: 'tests/*', element: <TestDesignRoutes /> },
      { path: 'profile/*', element: <ProfileRoutes /> },
      { path: 'campanies/*', element: <CampaniesRoutes /> },
      // { path: 'roles/*', element: <RolesRoutes /> },

    ],
  },
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Navigate to="/app/profile" />, exact: true },
    ],
  },
];
