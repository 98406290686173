import {
  Box,
  Flex,
  Link,
  Stack,
  Text,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import AUTH_NAV_ITEMS from '@features/Navigation/types/AuthenticatedNavItems';
import PUBLIC_NAV_ITEMS from '@features/Navigation/types/PublicNavItems';
import { useIsAuthenticated } from 'react-auth-kit';
import { ROLES } from '@features/profile/types/User';
import { useAuthorization } from '@/lib/authorization';

export default function DesktopNav() {
  const isAuthenticated = useIsAuthenticated();
  const navItems = isAuthenticated() ? AUTH_NAV_ITEMS : PUBLIC_NAV_ITEMS;
  const linkColor = useColorModeValue('gray.700', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const { checkAccess } = useAuthorization();
  const hasCompanyId = !!localStorage.getItem('companyId');
  const isSuperAdmin = checkAccess({ allowedRoles: [ROLES.SUPERADMIN] });
  const isCompanyOwner = checkAccess({ allowedRoles: [ROLES.COMPANYOWNER] });
  const isHiringManager = checkAccess({ allowedRoles: [ROLES.HIRINGMANAGER] });

  return (
    <Stack direction="row" spacing={4}>
      {navItems.map((navItem) => {
        if (navItem.label === 'Users' && !checkAccess({ allowedRoles: [ROLES.ADMIN] })) {
          return null;
        }
        if (navItem.label === 'Hired' && !checkAccess({ allowedRoles: [ROLES.ADMIN] })) {
          return null;
        }
        if (navItem.label === 'Companies') {
          if (hasCompanyId) {
            return null;
          }
          if (!isSuperAdmin) {
            return null;
          }
        }
        if (navItem.label === 'SuperAdmin Dashboard') {
          if (isSuperAdmin && hasCompanyId) {
            return null;
          }
          if (isCompanyOwner) {
            return null;
          }
          if (isHiringManager) {
            return null;
          }
        }

        if (navItem.label === 'SuperAdmins') {
          if (hasCompanyId) {
            return null;
          }
          if (!isSuperAdmin) {
            return null;
          }
        }
        if (navItem.label === 'Company Owner Dashboard') {
          if (!(isCompanyOwner || (isSuperAdmin && hasCompanyId))) {
            return null;
          }
        }
        if (navItem.label === 'Company Tests') {
          if (!(isCompanyOwner || (isSuperAdmin && hasCompanyId) || isHiringManager)) {
            return null;
          }
        }

        if (navItem.label === 'Tests') {
          if (hasCompanyId) {
            return null;
          }
          if (!isSuperAdmin) {
            return null;
          }
        }
        if (navItem.label === 'Assessment Library') {
          if (hasCompanyId) {
            return null;
          }
          if (!isSuperAdmin) {
            return null;
          }
        }

        if (navItem.label === 'Hiring Managers') {
          if (!(isCompanyOwner || (isSuperAdmin && hasCompanyId))) {
            return null;
          }
        }
        return (
          <Box key={navItem.label}>
            <Link
              as={RouterLink}
              p={2}
              to={navItem.href}
              fontSize={{ base: 'xs', lg: 'sm' }}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: 'none',
                color: linkHoverColor,
              }}
            >
              <Flex>
                <Icon as={navItem.icon} height="20px" width="20px" />
                <Text ml={2}>{navItem.label}</Text>
              </Flex>
            </Link>
          </Box>
        );
      })}
    </Stack>
  );
}
