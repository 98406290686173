/* eslint-disable react/require-default-props */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Select,
  Stack, Text, Textarea, VisuallyHiddenInput,
} from '@chakra-ui/react';
import CheckpointInputCandidate from '@features/candidateTest/components/Questions/CheckpointInputCandidate';
import parse from 'html-react-parser';
import {
  AssessmentAnswersDTO, QuestionAnswerDTO, QuestionDTO, TestCodeDTO,
} from '@features/candidateTest/types';
import { useFormContext } from 'react-hook-form';
import {
  SetStateAction, useEffect, useMemo, useState,
} from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import Symbols from '@assets/images/symbols.png';
import FullscreenIcon from '@assets/images/fullscreen.png';
import CodeEditorInput from '@/components/Forms/CodeEditorInput';
import { useLanguages } from '@/features/assessments/api/getLanguages';
import TestCasesConsoleAccordion from '../TestCasesConsoleAccordion';
import { useTestCode } from '../../api/testCode';

type QuestionWrapperProps = {
  question: QuestionDTO;
  index: number;
  currentQuestionIdx: number;
  templates?: { [key: string]: string };
  visibleTestCases?: { [key: string]: string }
};

function QuestionComponent({
  question, currentQuestionIdx, templates, visibleTestCases,
}:
{ question: QuestionDTO, currentQuestionIdx: number, templates?: { [key: string]: string }, visibleTestCases?: { [key: string]: string } }) {
  const {
    getValues, setValue,
  } = useFormContext<AssessmentAnswersDTO>();
  const [answers] = useState<QuestionAnswerDTO[]>([]);
  const getLanguagesQuery =  question.type === 'Coding' ? useLanguages() : null;
  const getLanguages = question.type === 'Coding' ? getLanguagesQuery?.data! : null;
  const [code, setCode] = useState('');
  const [theme, setTheme] = useState({ value: 'vs-dark', label: 'vs-dark' });
  const [resetKey, setResetKey] = useState(0);
  const [selectedLanguageId, setSelectedLanguageId] = useState<number | null>(null);
  const { mutate: testCodeMutation } = useTestCode();
  const [testResponses, setTestResponses] = useState<{ [key: string]: string }>({});
  const [isTesting, setIsTesting] = useState(false);

  useEffect(() => {
    setTheme({ value: 'vs-dark', label: 'vs-dark' });
  }, [code]);
  const resetCode = () => {
    setCode('');
    setResetKey((prevKey) => prevKey + 1);
  };
  const languageOptions = useMemo(
    () => Object.entries(templates ?? {}).map(([id, codeSource]) => {
      const languageId = parseInt(id, 10);
      const language = getLanguages && getLanguages?.find((lang) => lang.id === languageId);
      if (language) {
        return { value: languageId, label: language.name, codeSource };
      }
      return null;
    }).filter(Boolean),
    [getLanguages, templates],
  );

  const onCodeChange = (action: any, data: SetStateAction<string>) => {
    switch (action) {
      case 'code': {
        setCode(data);
        break;
      }
      default: {
        break;
      }
    }
  };
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  const toggleFullscreen = () => {
    if (isFullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    } else {
      const editorContainer = document.getElementById('code-editor-container');
      if (editorContainer?.requestFullscreen) {
        editorContainer.requestFullscreen();
        setIsFullscreen(true);
      }
    }
  };
  // Remove parenthesis and what's inside it and transform language to lowercase
  const getFormattedLanguageLabel = (label: string): string => {
    const parts = label.split('(');
    return parts[0].trim().toLowerCase();
  };
  const handleTestCode = async () => {
    if (!selectedLanguageId) {
      return;
    }

    setIsTesting(true);
    const testCasesArray = visibleTestCases
      ? Object.entries(visibleTestCases).map(([input, output]) => ({
        input,
        output,
      }))
      : [];

    try {
      for (const { input, output } of testCasesArray) {
        const sourceCodeBase64 = btoa(code);

        setValue(`answers.${currentQuestionIdx}.answer`, sourceCodeBase64);
        setValue(`answers.${currentQuestionIdx}.languageId`, selectedLanguageId);

        const testCodeData: TestCodeDTO = {
          language_id: selectedLanguageId,
          source_code: btoa(code),
          stdin: input,
          expected_output: output,
        };
        await new Promise<void>((resolve, reject) => {
          testCodeMutation(testCodeData, {
            onSuccess: (data) => {
              const testResult = data.status?.description || '';
              setTestResponses((prevResponses) => ({
                ...prevResponses,
                [input]: testResult,
              }));
              resolve();
            },
            onError: (error) => {
              reject(error);
            },
          });
        });
      }
    } catch (error) {
      // No need to log the error here, as it has already been caught
    } finally {
      setIsTesting(false);
    }
  };
  const getCodeFromTemplates = (languageId: any, templatess: any) => {
    const base64Code = templatess && templatess[languageId];
    if (base64Code) {
      try {
        const decodedCode = atob(base64Code);
        return decodedCode;
      } catch (error) {
        // No need to log the error here, as it has already been caught
      }
    }
    return '';
  };

  const handleLanguageSelect = (e: any) => {
    const selectedLanguageIds = Number(e.target.value);
    setSelectedLanguageId(selectedLanguageIds);
    setValue(`answers.${currentQuestionIdx}.languageId`, selectedLanguageIds);
    const codeForSelectedLanguage = getCodeFromTemplates(selectedLanguageIds, templates);
    setCode(codeForSelectedLanguage);
  };

  useEffect(() => {
    // When the selected language changes, set the code (placeholder) for the selected language.
    const codeForSelectedLanguage = getCodeFromTemplates(selectedLanguageId, templates);
    setCode(codeForSelectedLanguage);
  }, [selectedLanguageId, templates]);

  useEffect(() => {
    const encodedCode = btoa(code);
    setValue(`answers.${currentQuestionIdx}.answer`, encodedCode);
  }, [code, currentQuestionIdx, selectedLanguageId, setValue]);

  switch (question.type) {
    case 'MCQ':
      return (
        <CheckpointInputCandidate
          key={question.id}
          label={parse(question.statement) as string}
          options={question.options}
          fieldName={`answers.${currentQuestionIdx}.answerIds`}
          getValues={getValues}
          setValue={setValue}
        />
      );
    case 'OEQ':
      return (
        <Stack height="80%">
          <Textarea
            placeholder="Write your answer here..."
            value={answers[currentQuestionIdx]?.answer}
            onChange={(event) => {
              setValue(`answers.${currentQuestionIdx}.answer`, event.target.value);
            }}
            width="800px"
            height="100%"
          />
        </Stack>
      );
    case 'Coding':
      return (
        <Flex direction="column" id="code-editor-container">
          <Flex
            width="832px"
            height="43px"
            color="#252525"
            bgColor="#252525"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="-9px"
            onChange={(e) => setSelectedLanguageId(Number((e.target as HTMLSelectElement).value))}
          >
            <Select
              placeholder="Select Language"
              color="white"
              bgColor="#1E1D22"
              w="212px"
              h="43px"
              border="none"
              value={selectedLanguageId ?? ''}
              onChange={handleLanguageSelect}
            >
              {languageOptions.map((language) => (
                <option key={language?.value ?? ''} value={language?.value ?? ''} style={{ color: 'black' }}>
                  {language?.label ?? ''}
                </option>
              ))}

            </Select>
            <Button
              color="white"
              fontWeight="275"
              fontSize="14px"
              bgColor="transparent"
              onClick={toggleFullscreen}
            >
              <Image src={FullscreenIcon} mr={2} />
            </Button>
          </Flex>
          <Box>
            <CodeEditorInput
              key={resetKey}
              code={code}
              language={
                selectedLanguageId !== null
                  ? getFormattedLanguageLabel(
                    languageOptions.find((lang) => lang?.value === selectedLanguageId)?.label || 'javascript',
                  )
                  : 'javascript'
              }
              theme={theme}
              onCodeChange={onCodeChange}
              width="834px"
              height={isFullscreen ? '70vh' : '450px'}
            />
          </Box>
          <Flex
            width="833px"
            height="52px"
            color="#252525"
            bgColor="#252525"
            alignItems="center"
            justifyContent="space-between"
            marginTop="-1px"
          >
            <Button color="#959595" fontWeight="275" fontSize="14px" bgColor="transparent" onClick={() => { resetCode(); }}>
              <Icon as={FiRefreshCcw} color="#959595" boxSize={3} mr={2} />Reset
            </Button>
            <Button
              color="white"
              fontWeight="275"
              fontSize="14px"
              bgColor="transparent"
              border="1px solid #4E4E4E"
              onClick={handleTestCode}
              isDisabled={isTesting}
              mr={4}
            >
              <Image src={Symbols} mr={2} />
              Test My Code
            </Button>
          </Flex>
          <TestCasesConsoleAccordion visibleTestCases={visibleTestCases || {}} testResponses={testResponses} />
        </Flex>
      );
    default:
      return null;
  }
}

export default function QuestionWrapper({
  question,
  index,
  currentQuestionIdx,
  templates,
  visibleTestCases,
}: QuestionWrapperProps) {
  const { register } = useFormContext<AssessmentAnswersDTO>();

  return (
    <Flex
      key={question.id}
      hidden={currentQuestionIdx !== index}
      direction="column"
      justifyContent="center"
      alignItems="center"
      margin={10}
      minH="50vh"
    >
      <VisuallyHiddenInput value={question.id} {...register(`answers.${index}.questionId`)} />
      <VisuallyHiddenInput value={question.type} {...register(`answers.${index}.questionType`)} />

      {question.type === 'Coding' ? (
        <Flex
          id="code-editor-container"
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          width="100%"
          marginBottom={10}
        >
          <Box flex="1" marginRight={4}>
            <Text fontSize="16px" color="#C9C9C9">Statement</Text>
            <Text
              fontSize="xl"
              fontWeight="bold"
              color="#E7EDF3"
            >
              {parse(question.statement) as string}
            </Text>
          </Box>
          <Box flex="1">
            <QuestionComponent
              question={question}
              currentQuestionIdx={currentQuestionIdx}
              templates={templates}
              visibleTestCases={visibleTestCases}
            />
          </Box>
        </Flex>
      ) : (
        <Box>
          <Text
            fontSize="2xl"
            fontWeight="semi-bold"
            marginBottom={10}
          >
            {parse(question.statement) as string}
          </Text>
          <QuestionComponent
            question={question}
            currentQuestionIdx={currentQuestionIdx}
            templates={templates}
            visibleTestCases={visibleTestCases}
          />
        </Box>
      )}
    </Flex>
  );
}

QuestionComponent.defaultProps = {
  templates: {},
  visibleTestCases: {},
};
