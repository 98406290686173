import { Route, Routes } from 'react-router-dom';
import StartedTestPage from '@features/candidateTest/pages/StartedTestPage';
import TestCanceled from '@components/Errors/UnfoundTest';
import LandingPage from '../pages/LandingPage';
import TestExpired from '@/components/Errors/TestExpired';
import InvitationExpired from '@/components/Errors/InvitationExpired';

function StartTestRoutes() {
  return (
      <Routes>
        <Route path=":UniqueTestId" element={<LandingPage />} />
        <Route path=":UniqueTestId/start" element={<StartedTestPage />} />
        <Route path="testCanceled" element={<TestCanceled />} />
        <Route path="testExpired" element={<TestExpired />} />
        <Route path='invitationExpired' element={<InvitationExpired />} />
      </Routes>
  );
}
export default StartTestRoutes;
