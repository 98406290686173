import {
  Fade, Grid, GridItem, Spinner,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTestAndCandidate } from '@features/candidateTest/api/getTestAndCandidate';
import TestInfoCard from '@features/candidateTest/components/TestInfoCard';
import TestAssessmentsAccordion from '@features/candidateTest/components/TestAssessmentsAccordion';
import BeforeYouStart from '@features/candidateTest/pages/BeforeYouStart';
import { useState } from 'react';
import ChangeCandidateName from './ChangeCandidateName';

export default function StartTest() {
  const { UniqueTestId } = useParams();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  const testAndCandidateQuery = useTestAndCandidate({
    // @ts-ignore

    UniqueTestId,
    config: { cacheTime: 1000 * 60 * 60 * 2 }, // 2 hours of cache should be enough
  });
  const handleFormSubmit = () => {
    setShowForm(false);
  };
  const getLanguage = testAndCandidateQuery.data!;
  const language = getLanguage?.assignedTest?.test?.assessments?.[0]?.language;
  const frTranslate = language === 'French' ? 'fr' : 'en';
  
  const [showBeforeYouStart, setShowBeforeYouStart] = useState(false);

  const onToggle = () => {
    setShowBeforeYouStart(true);
  };

  const handleBack = () => {
    setShowBeforeYouStart(false);
  };

  if (testAndCandidateQuery.isFetching) {
    return (<Spinner />);
  }
  if (testAndCandidateQuery.data!.assignedTest.isCanceled) {
    navigate('/testCanceled');
  }
  if (testAndCandidateQuery.data!.assignedTest.isStarted) {
    navigate('start');
  }
  return (
    <div>
      {showForm ? (
        <ChangeCandidateName toggleRulesPage={handleFormSubmit} language={frTranslate}/>
      ) : (
        <>
          {showBeforeYouStart ? (
            <Fade in={showBeforeYouStart}>
              <BeforeYouStart onBack={handleBack} toggleRulesPage={onToggle} language={frTranslate} />
            </Fade>
          ) : (
            <Grid templateColumns="repeat(12, 1fr)" marginY={10} marginX={20} gap={10}>
              <GridItem colSpan={7}>
                <TestInfoCard toggleRulesPage={onToggle} />
              </GridItem>
              <GridItem colSpan={5}>
                <TestAssessmentsAccordion />
              </GridItem>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}
