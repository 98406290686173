export type User = {
  id?:string;
  firstName: string;
  lastName:string;
  email: string;
  password:string;
  role :ROLES;
};

export type UserDTO = {
  id?:string;
  firstName: string;
  lastName:string;
  email?: string;
  password?:string;
  role: ROLES;
  companyId: string;
};
export type UpdatePasswordDTO = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export enum ROLES {
  ADMIN = 'Admin',
  HIRINGMANAGER = 'HiringManager',
  SUPERADMIN = 'SuperAdmin',
  COMPANYOWNER = 'CompanyOwner',
}
