import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { useNotificationStore } from '@/stores/notifications';

export const useLogout = () => {
  const signOut = useSignOut();
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const logoutFn = () => {
    if (signOut()) navigate('/auth/login');
    addNotification({
      type: 'info',
      title: 'Successfully logged out',
    });
  };
  return { logoutFn };
};
