import {
  Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useQueryClient } from 'react-query';
import { AssignmentDTO } from '@features/candidateTest/types';
import { useParams } from 'react-router-dom';
import { CgTimer } from 'react-icons/cg';
import TagWithIcon from '@components/TagWithIcon';
import { formatToHHMMSS } from '@utils/format';

export default function TestAssessmentsAccordion() {
  const { UniqueTestId } = useParams();
  const queryClient = useQueryClient();
  const assignment : AssignmentDTO = queryClient.getQueryData(['testInfo', UniqueTestId])!;
  return (
    <Accordion allowMultiple border="transparent">
      {assignment.assignedTest.test.assessments.map((assessment) => (
        <AccordionItem
          color="#828A92"
          bg="white"
          rounded="xl"
          mb={4}
          key={assessment.id}
        >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  p="1.5rem"
                  _hover={
                    { bg: 'transparent' }
                }
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    textTransform="capitalize"
                    color={isExpanded ? 'black' : '#828A92'}
                    fontWeight={isExpanded ? 'bold' : 'medium'}
                  >
                    {assessment.title}
                  </Box>
                  {isExpanded ? (
                    <MinusIcon fontSize="14px" />
                  ) : (
                    <AddIcon fontSize="14px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Flex flexDirection="column" mb={5} gap={4}>
                  <TagWithIcon
                    color="#6D6B6B"
                    label={formatToHHMMSS(assessment.duration)}
                    leftIcon={CgTimer}
                  />
                  <Text fontSize="0.75rem">
                    {assessment.description}
                  </Text>

                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}

      <AccordionItem />
    </Accordion>
  );
}
