import {
  Button,
  Flex,
  Stack,
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import TextInput from '@components/Forms/TextInput';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import womanIcon from '@assets/images/womanIcon.png';
import { ChevronRightIcon } from '@chakra-ui/icons';
import axios from '@/lib/axios';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

  type ChangeCandidateNameDTO = {
    firstName: string;
    lastName: string;
  };

  type PropTypes = {
    toggleRulesPage: () => void;
    language?: string;
  };
export default function ChangeCandidateName({ toggleRulesPage, language } : PropTypes) {
  const { UniqueTestId } = useParams();
  const { t, i18n } = useTranslation(nameSpaces.candidateInstructions);
  const {
    register, handleSubmit, formState: { errors }, watch,
  } = useForm<ChangeCandidateNameDTO>();
  const isFormValid = watch('firstName') && watch('lastName');
  // detect language change
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const onSubmit = (data: ChangeCandidateNameDTO) => {
    axios.post(`CandidateTest/ChangeCandidateName/${UniqueTestId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      toggleRulesPage();
    }).catch((error) => {
      console.log('error', error);
    });
  };
  return (
    <Flex justifyContent="center" alignItems="center" pt={20}>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Image src={womanIcon} />
        <Box
          borderRadius="35px"
          width="1032px"
          height="391px"
          backgroundColor="#FFFFFF"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap={10} margin={10} justifyContent="center" alignItems="center">
              <Stack spacing={7} color="black">
                <Text fontSize="22px" color="#0F0F0F" fontWeight="600">{t('candidateName.fillForm')}</Text>
                <Text
                  fontSize="14px"
                  color="#818181"
                  fontWeight="500"
                  textAlign="center"
                >{t('candidateName.essentialStep')}
                </Text>
              </Stack>
              <Flex borderRadius="10px" gap={10} margin={10} justifyContent="center">
                <TextInput
                  errorMessage={errors.firstName?.message}
                  label=""
                  register={register('firstName', { required: true })}
                  backgroundColor="white"
                  borderRadius="10px"
                  placeholder={t('candidateName.firstName')}
                />
                <TextInput
                  errorMessage={errors.lastName?.message}
                  label=""
                  register={register('lastName', { required: true })}
                  backgroundColor="white"
                  borderRadius="10px"
                  placeholder={t('candidateName.lastName')}
                />
              </Flex>
              <Flex justifyContent="flex-end" width="100%">
                <Button
                  type="submit"
                  fontWeight="400"
                  color={isFormValid ? '#FFFFFF' : '#B9BDC1'}
                  backgroundColor={isFormValid ? '#A55AFF' : '#EBECF0'}
                  fontSize="14px"
                  w="204px"
                  h="40px"
                  mr={12}
                  disabled={!isFormValid}
                >
                  {t('candidateName.nextButton')} <ChevronRightIcon boxSize={5} />
                </Button>

              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>

  );
}
