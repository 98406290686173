import axios from '@lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query';
import { useQuery } from 'react-query';
import { AssessmentDTO } from '@features/candidateTest/types';

export const getCurrentAssessment = (UniqueTestId:string):Promise<AssessmentDTO> => axios.get(`/CandidateTest/GetCurrentAssessment/${UniqueTestId}`);

type QueryFnType = typeof getCurrentAssessment;

type UseTestAndCandidateOptions = {
  UniqueTestId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useCurrentAssessment = ({ UniqueTestId, config } : UseTestAndCandidateOptions) => useQuery<ExtractFnReturnType<QueryFnType>>({
  ...config,
  queryKey: ['currentAssessment'],
  queryFn: () => getCurrentAssessment(UniqueTestId),
});
