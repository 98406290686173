import React from 'react';
import CustomErrorPage from './CustomErrorPage';

export default function TestExpired() {
  return (
    <CustomErrorPage
      title="Test Expired"
      description="Please verify that the link you entered is the one we sent you through email.
      Contact us if the problem persists"
    />
  );
}
