import {
  Flex, Stack, Text,
} from '@chakra-ui/react';
import { Dispatch, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AssessmentDTO, AssignmentDTO } from '@features/candidateTest/types';
import TagWithIcon from '@components/TagWithIcon';
import { formatToHHMMSS } from '@utils/format';
import { CgTimer } from 'react-icons/cg';
import { BsFillQuestionOctagonFill } from 'react-icons/bs';
import AssessmentFinishedCard from '@features/candidateTest/components/AssessmentFinishedCard';
import { useAssessmentDetails } from '@features/candidateTest/api/getAssessmentDetails';
import FullPageSpinner from '@components/FullPageSpinner';
import AssessmentDetailsCard from '@features/candidateTest/components/AssessmentDetailsCard';
import axios from '@lib/axios';
import FeedbackPage from '../components/FeedbackPage';
import { useFullScreenExit } from '@/hooks/useFullScreenExit';
import ExitFullScreenModal from '../components/ExitFullScreenModal';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  setAssessmentBridgePage : Dispatch<boolean>;
};
export default function AssessmentCompleted({ setAssessmentBridgePage } : PropTypes) {
  const { UniqueTestId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation(nameSpaces.candidateInstructions);
  const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState(false);
  const testData = queryClient.getQueryData(['testInfo', UniqueTestId]) as AssignmentDTO;
  const oldCurrentAssessment = queryClient.getQueryData(['currentAssessment']) as AssessmentDTO;
  // @ts-ignore
  const submittedAssessmentsQuery = useAssessmentDetails({ UniqueTestId });
  const [counter, setCounter] = useState(10);
  const testIsCompleted = oldCurrentAssessment.order + 1 === testData.assignedTest.test.assessments.length;
  const openFullScreenModal = () => {
    setIsFullScreenModalOpen(true);
  };
  const resumeFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      try {
        document.documentElement.requestFullscreen();
      } catch (error) {
        //
      }
    } else {
      //
    }
  };
  const alertOnFullScreenExit = () => queryClient.fetchQuery('alertOnFullScreenExit', () => axios
    .post(`/CandidateTest/MouseLeaveDetected/${UniqueTestId}`));
  useFullScreenExit(alertOnFullScreenExit, openFullScreenModal);
  const exitFullScreen = () => {
    if (document?.fullscreenElement) {
      document.exitFullscreen();
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (!testIsCompleted) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);
    const timer = setTimeout(async () => {
      if (!testIsCompleted) {
        await queryClient.invalidateQueries(['currentAssessment']);
        setAssessmentBridgePage(false);
      }
    }, 10000);
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
      if (testIsCompleted) {
        exitFullScreen();
      }
    };
  }, [queryClient, setAssessmentBridgePage, testIsCompleted]);
  if (submittedAssessmentsQuery.isFetching) {
    return (
      <FullPageSpinner />
    );
  }
  const submittedAssessments = submittedAssessmentsQuery.data!;
  const lastAssessment = submittedAssessments.assessmentDetails[submittedAssessments.nextAssessmentIndex - 2];

  return (
    <Flex
      flexDirection="column"
      bg="white"
      minH="80vh"
      marginX={10}
      rounded="30px"
      paddingY="30px"
      paddingX={20}
      justifyContent="space-evenly"
      maxW="90vw"
    >
      {testIsCompleted ? (
        <Stack>
          <AssessmentFinishedCard />
          <FeedbackPage />
        </Stack>
      )
        : (
          <Flex
            flexDirection="column"
            bg="white"
            justifyContent="space-evenly"
            gap={10}
          >
            <AssessmentDetailsCard assessment={lastAssessment} />
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={5}
            >
              <Text
                color="#9747FF"
                fontWeight="medium"
                fontSize="sm"
              >{t('assessmentCompleted.nextAssessment')}
              </Text>
              <Text
                fontWeight="bold"
                fontSize="2xl"
                textTransform="capitalize"
              >{testData?.assignedTest?.test?.assessments[oldCurrentAssessment.order + 1]?.title}
              </Text>
              <Text
                color="#828A92"
                fontWeight="normal"
                fontSize="sm"
              >{testData?.assignedTest?.test?.assessments[oldCurrentAssessment.order + 1]?.description}
              </Text>
              <Flex gap={10}>
                <TagWithIcon
                  label={`Assessment Duration: ${formatToHHMMSS(testData?.assignedTest?.test?.assessments[oldCurrentAssessment.order + 1]?.duration)}`}
                  leftIcon={CgTimer}
                  color="#6D6B6B"
                />
                <TagWithIcon
                  label={`Assessment Questions : ${testData?.assignedTest?.test?.assessments[oldCurrentAssessment.order + 1]?.numberOfQuestions}`}
                  leftIcon={BsFillQuestionOctagonFill}
                  color="#6D6B6B"
                />
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={5}
            >
              <Text
                fontSize="sm"
                color="#BEBEBE"
              >{t('assessmentCompleted.beAware')}
              </Text>
              <Text
                fontWeight="bold"
                color="#A55AFF"
              >{t('assessmentCompleted.willBeRedirected')}
              </Text>
              <Text
                fontWeight="bold"
                color="#A55AFF"
              >{counter > 0 ? counter : '0'} seconds
              </Text>
            </Flex>
          </Flex>
        )}
      {!testIsCompleted && (
      <ExitFullScreenModal
        isOpen={isFullScreenModalOpen}
        onClose={() => setIsFullScreenModalOpen(false)}
        onConfirm={() => setIsFullScreenModalOpen(false)}
        onResume={resumeFullScreen}
      />
      )}
    </Flex>
  );
}
