import {
  Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

import answersData from '@features/landingPage/components/data/QuestionAnswers.json';
import { useEffect, useState } from 'react';

interface Answer {
  id: number;
  title: string;
  content: string;
}
export default function PlatformQuestionsAccordion() {
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);

  useEffect(() => {
    setAnswers(answersData.items);
  }, []);

  const handleAccordionItemClick = (index: number) => {
    if (index === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };
  // const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');
  return (
    <Box
      ml="190px"
      overflowX="hidden"
      m={0}
      p={0}
    >
      <Accordion allowMultiple border="transparent">
        {answers.map((answer, index) => (
          <AccordionItem
            color="#828A92"
            bg="white"
            rounded="xl"
            mb={4}
            key={answer.id}
            style={{
              border: `1px solid ${
                index === expandedIndex ? '#176FFF' : '#EBECF0'
              }`,
              maxWidth: `${['540', '500', '800']}`,
            }}
          >
            <>
              <h2>
                <AccordionButton
                  p="1.5rem"
                  _hover={{ bg: 'transparent' }}
                  onClick={() => handleAccordionItemClick(index)}
                  tabIndex={0}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    textTransform="capitalize"
                    fontSize={['12', '14', '18']}
                    fontWeight="500"
                    color={index === expandedIndex ? '#176FFF' : '#333333'}
                  >
                    {answer.title}
                  </Box>
                  {index === expandedIndex ? (
                    <MinusIcon fontSize="14px" color="#176FFF" />
                  ) : (
                    <AddIcon fontSize="14px" />
                  )}
                </AccordionButton>
              </h2>
              {index === expandedIndex && (
              <AccordionPanel pb={4}>
                <Flex flexDirection="column" mb={5} gap={4}>
                  <Text fontSize="16px" fontWeight="500" color="#333333" ml={2}>{answer.content}</Text>
                </Flex>
              </AccordionPanel>
              )}
            </>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}
