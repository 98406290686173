import { extendTheme } from '@chakra-ui/react';

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

const theme = extendTheme({
  breakpoints,
  fonts: {
    body: '\'poppins\', sans-serif',
  },
  textStyles: {
    h2: {
      fontSize: '2xl',
      fontWeight: '600',
      lineHeight: '32px',
      textTransform: 'capitalize',
      dropShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
});

export default theme;
