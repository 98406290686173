import {
  FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement, InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type TextInputProps = {
  label: string,
  errorMessage: string | undefined,
  register?: UseFormRegisterReturn<any>;
  placeholder? : string;
  readonly? : boolean;
  LeftElement?: React.ReactNode;
  RightElement?: React.ReactNode;
  type?: string;
  // All other props
  [x:string]: any;
};
export default function TextInput({
  label, errorMessage, register, placeholder, readonly, type, LeftElement, RightElement, ...rest
} : TextInputProps) {
  return (
    <FormControl isInvalid={Boolean(errorMessage)} {...rest}>
      {label && (
        <FormLabel fontWeight="normal">
          {label}
        </FormLabel>
      )}

      <InputGroup>
        {LeftElement && (
          <InputLeftElement pointerEvents="none">
            {LeftElement}
          </InputLeftElement>
        )}
        <Input
          placeholder={placeholder}
          {...register}
          readOnly={readonly}
          type={type}
        />

        {RightElement && (
          <InputRightElement pointerEvents="none">
            {RightElement}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>
        {errorMessage && errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
}
TextInput.defaultProps = {
  placeholder: '',
  readonly: false,
  register: undefined,
  LeftElement: undefined,
  RightElement: undefined,
  type: 'text',
};
