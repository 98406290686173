import axios from '@lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query';
import { useQuery } from 'react-query';

export const getUTCTimeNow = ():
Promise<string> => axios.get('/candidatetest/time-utc-now');

type QueryFnType = typeof getUTCTimeNow;

type UseGetUTCTimeNowOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useUTCTimeNow = ({...config }: UseGetUTCTimeNowOptions = {}) => useQuery<ExtractFnReturnType<QueryFnType>>({
  ...config,
  queryKey: ['utc-time-now'],
  queryFn: () => getUTCTimeNow(),
});
