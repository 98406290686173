import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Image,
  Box,
  Text,
} from '@chakra-ui/react';
import RedBox from '@assets/images/redBox.png';
import Box1 from '@assets/images/box1.png';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';

interface ExitFullScreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onResume: () => void;
}

function ExitFullScreenModal({
  isOpen, onClose, onConfirm, onResume,
}: ExitFullScreenModalProps) {
  const { t } = useTranslation(nameSpaces.candidateInstructions);
  useEffect(() => {
    const handleOverlayClick = (event: MouseEvent) => {
      if (event.target === event.currentTarget) {
        onResume();
        onConfirm();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOverlayClick);
    }

    return () => {
      document.removeEventListener('click', handleOverlayClick);
    };
  }, [isOpen, onConfirm, onResume]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="29px" w="100%">
        <ModalHeader p={0} borderBottom="none" position="relative">
          <Image src={RedBox} alt="Header Image" w="100%" h="100%" objectFit="cover" />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={1}
          >
            <Image src={Box1} alt="Box1 Image" />
          </Box>
        </ModalHeader>
        <ModalBody textAlign="center">
          <Box mb="20px">
            <Text fontSize="22px" fontWeight="600" color="#333333" mt={10}>{t('fullScreenModal.restriction')}</Text>
          </Box>
          <Text fontSize="14px" fontWeight="400" color="#333333" mt={6}>
          {t('fullScreenModal.exitingFullScreen')}
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#333333">{t('fullScreenModal.testingEnv')}</Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            color="white"
            bgColor="#E55353"
            w="294px"
            h="50px"
            borderRadius={8}
            fontSize="14px"
            fontWeight="500"
            onClick={() => {
              onResume();
              onConfirm();
            }}
          >
            {t('fullScreenModal.resumeButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ExitFullScreenModal;
