import { Flex, Image } from '@chakra-ui/react';
import gomycodeLogo from '@assets/images/gomycodeLogo.png';

type PropTypes = {
  children: React.ReactNode;
};

export default function CandidateWorkflowLayout({ children }: PropTypes) {
  return (
    <Flex flexDirection="column" width="100wh" minH="100vh" bg="#F6F6F6">
      <Flex justifyContent="flex-start" alignItems="center" margin="30px">
        <Image src={gomycodeLogo} />
      </Flex>
      <main style={{ minWidth: '100%' }}>{children}</main>
    </Flex>
  );
}
