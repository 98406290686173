import { useState } from 'react';
import {
  Button, Flex, Image, Stack, Text, Textarea,
} from '@chakra-ui/react';
import PaperPlane from '@assets/images/paper-plane.png';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';

export default function FeedbackPage() {
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation(nameSpaces.candidateInstructions);

  const handleInputChange = (event: any) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = () => {
    setSubmitted(true);
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={5}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={5}
        mt={5}
      >
        <Image src={PaperPlane} />
        <Text
          fontSize="xl"
          fontWeight="bold"
          textTransform="capitalize"
          color="#9747FF"
        >
          {t('feedback.valueFeedback')}
        </Text>
        {!submitted && (
        <Text
          fontSize="16px"
          color="#828A92"
        >
          {t('feedback.letUsKnow')}
        </Text>
        )}
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={5}
      >
        {submitted ? (
          <Stack>
            <Text fontSize="16px" color="#828A92" fontWeight="600">
            {t('feedback.messageReceived')}
            </Text>
            <Text fontSize="16px" color="#828A92" textAlign="center" fontWeight="600">
            {t('feedback.messageValuable')}
            </Text>
          </Stack>
        ) : (
          <>
            <Textarea w="936px" h="147px" placeholder={t('feedback.yourFeedback')} onChange={handleInputChange} />
            {feedback && (
              <Button w="204px" h="50px" bgColor="#9747FF" color="white" borderRadius="8px" fontWeight="400" onClick={handleSubmit}>
                {t('feedback.sendFeedback')}
              </Button>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}
