import React from 'react';
import CustomErrorPage from './CustomErrorPage';

export default function InvitationExpired() {
  return (
    <CustomErrorPage
      title="Invitation Expired"
      description="It looks like the invitation you’re trying to use has expired.
       If you believe this is a mistake or need further assistance, please reach out to our support team."
    />
  );
}
