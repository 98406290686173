import {
  Box, Image, Stack, Text,
} from '@chakra-ui/react';
import React from 'react';

type PropTypes = {
  image: string;
  label: string;
  text: string
  [x:string] : any;
};
export default function CodingTestRulesCard({
  label, text, image, ...rest
}:PropTypes) {
  return (
    <Stack {...rest}>
      <Box
        w="528px"
        h="128px"
        border="1px solid #E2E2E2"
        borderRadius="20px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0 16px"
      >
        <Stack>
          <Text fontSize="18px" fontWeight="600" color="#000000">
            {label}
          </Text>
          <Text fontSize="14px" fontWeight="500" color="#333333">
            {text}
          </Text>
        </Stack>
        <Image mt={-20} mr={3} src={image} />
      </Box>
    </Stack>
  );
}
