import { IconType } from 'react-icons';
import {
  Card, CardBody, Text, VStack,
} from '@chakra-ui/react';

type PropTypes = {
  Icon: IconType;
  label: string;
  [x:string] : any;
};
export default function BeforeYouStartCard({ label, Icon, ...rest }: PropTypes) {
  return (
    <Card rounded="2xl" direction="row" alignItems="center" variant="outline" minW="200px" maxW="230px" {...rest}>
      <CardBody>
        <VStack>
          <Icon color="#9E9E9E" size="26px" />
          <Text fontSize="sm" fontWeight="semi-bold" textAlign="center">{label}</Text>
        </VStack>
      </CardBody>

    </Card>
  );
}
