import {
  Checkbox, GridItem,
} from '@chakra-ui/react';
import { OptionDTO } from '@features/candidateTest/types';
import { useState } from 'react';

type CustomCheckboxProps = { option : OptionDTO, onChange: () => void };
export default function CustomCheckpoint({ option, onChange } : CustomCheckboxProps) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <Checkbox
      value={option.id.toString()}
      size="lg"
      bg={isChecked ? '#9747FF' : 'white'}
      borderRadius="10px"
      border="1px solid #E1E1E1"
      spacing={5}
      padding={5}
          // using {...register('questions')} in the checkpoints creates bugs when
          // using paginations and selecting other questions (checkpoints)
          // so we use setValue to manually update form state
      onChange={() => {
        onChange();
        setIsChecked(!isChecked);
      }}
    >
      <GridItem
        fontSize={{ lg: 'lg', sm: 'sm' }}
        color={isChecked ? 'white' : 'black'}
      >
        {option.statement}
      </GridItem>
    </Checkbox>
  );
}
