import axios from '@lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query';
import { useQuery } from 'react-query';

type Languages = {
  id: number;
  name: string;
};
export const getLanguages = (): Promise<Languages[]> => axios.get('/Coding/GetLanguages');

type QueryFnType = typeof getLanguages;

type UseLanguagesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLanguages = ({ config = {} }: UseLanguagesOptions = {}) => useQuery<ExtractFnReturnType<QueryFnType>>({
  ...config,
  queryKey: ['languages'],
  queryFn: () => getLanguages(),
});
