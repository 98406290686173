import axios from '@lib/axios';
import { useMutation } from 'react-query';
import { MutationConfig, queryClient } from '@lib/react-query';
import { AssessmentAnswersDTO } from '@features/candidateTest/types';

// eslint-disable-next-line max-len
export const submitAssessmentAnswers = (data:AssessmentAnswersDTO): Promise<any> => {
  const { uniqueTestId } = data;
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  delete data.uniqueTestId;
  return axios.post(`/CandidateTest/SubmitAssessment/${uniqueTestId}`, data);
};

type UseSubmitAssessmentAnswers = {
  config?: MutationConfig<typeof submitAssessmentAnswers>;
};

export const useSubmitAssessmentAnswers = ({ config }: UseSubmitAssessmentAnswers = {}) => useMutation({
  onMutate: async () => {
    await queryClient.cancelQueries('CurrentAssessment');
  },
  onError: (err) => {
    // @ts-ignore
    if (err?.response?.status === 403 || err?.response?.status === 401) {
      console.log(err);
    }
  },
  onSuccess: () => {
    queryClient.invalidateQueries('CurrentAssessment');
  },
  mutationFn: submitAssessmentAnswers,
  ...config,
});
