import axios from '@lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query';
import { useQuery } from 'react-query';
import { AssessmentsDetails } from '@features/candidateTest/types';

// eslint-disable-next-line max-len
export const getAssessmentDetails = (UniqueTestId:string):Promise<AssessmentsDetails> => axios.get(`/CandidateTest/AssessmentDetails/${UniqueTestId}`);

type QueryFnType = typeof getAssessmentDetails;

type UseAssessmentDetailsOptions = {
  UniqueTestId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useAssessmentDetails = ({ UniqueTestId, config } : UseAssessmentDetailsOptions) => useQuery<ExtractFnReturnType<QueryFnType>>({
  ...config,
  queryKey: ['submittedAssessmentsDetails'],
  queryFn: () => getAssessmentDetails(UniqueTestId),
});
