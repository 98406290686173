import {
  Box,
  Flex, Image, Stack, Text, useMediaQuery,
} from '@chakra-ui/react';
import logoGMC from '@assets/images/logo.png';
import { Link } from 'react-scroll';
import { ArrowUpIcon } from '@chakra-ui/icons';

export default function Footer() {
  const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');
  return (
  // <Flex maxHeight="30vh">
    <Stack>
      <Box justifyContent="center" mt="-670px" ml={isSmallerThan768 ? '540px' : '10px'}>
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Image src={logoGMC} ml={10} mt={isSmallerThan768 ? '-6' : '7'} alt="logoGMC" />
          <Flex align="center" ml={isSmallerThan768 ? '39' : '45'} mt={8}>
            <Link
              to="about"
              smooth
              duration={500}
              offset={-60}
              style={{
                color: '#176FFF',
                marginRight: '20px',
                fontWeight: '500',
                fontSize: '17px',
              }}
            >
              About
            </Link>
            <Link
              to="candidate"
              smooth
              duration={500}
              offset={-60}
              style={{
                color: '#176FFF',
                marginRight: '15px',
                marginLeft: '15px',
                fontWeight: '500',
                fontSize: '17px',
              }}
            >
              Candidates
            </Link>
            <Link
              to="test"
              smooth
              duration={500}
              offset={-60}
              style={{
                color: '#176FFF',
                marginLeft: '25px',
                fontWeight: '500',
                fontSize: '17px',
              }}
            >
              Tests
            </Link>
          </Flex>
          <Text
            fontSize="10px"
            fontWeight="500"
            color="#B4B4B4"
            textTransform="capitalize"
            mt={8}
            w={isSmallerThan768 ? '296px' : ''}
            ml={isSmallerThan768 ? '20' : '0'}
          >
            &quot;GOMYCODE aims to be a Pan-African educational platform that helps the talents of tomorrow improve
          </Text>
          <Text
            fontSize="10px"
            fontWeight="500"
            color="#B4B4B4"
            textTransform="capitalize"
            w={isSmallerThan768 ? '296px' : ''}
            ml={isSmallerThan768 ? '20' : '0'}
          >
            their digital skills through affordable and quality training and the assistance of the best instructors.&quot;
          </Text>
        </Flex>
      </Box>
      <Flex justifyContent="center">
        <Box
          ml={['800', '850', '650', '700', '700', '1000']}
          mt={isSmallerThan768 ? '-5px' : '-60px'}
        >
          <Box
            width={isSmallerThan768 ? '46px' : '86px'}
            height={isSmallerThan768 ? '46px' : '86px'}
            border="1px solid #176FFF"
            borderRadius="200px"
          >
            <Link
              to="home"
              smooth
              duration={500}
              offset={-60}
              style={{
                color: 'white',
                display: 'inline-block',
                width: `${isSmallerThan768 ? '37.44px' : '70px'}`,
                height: `${isSmallerThan768 ? '37.44px' : '70px'}`,
                backgroundColor: '#176FFF',
                borderRadius: '200px',
                textAlign: 'center',
                lineHeight: `${isSmallerThan768 ? '40px' : '70px'}`,
                marginTop: `${isSmallerThan768 ? '4px' : '7px'}`,
                marginLeft: `${isSmallerThan768 ? '4px' : '7px'}`,
                cursor: 'pointer',
                //   marginRight: '20px',
              }}
            >
              <ArrowUpIcon boxSize={isSmallerThan768 ? '5' : '10'} />
            </Link>
          </Box>
        </Box>

      </Flex>
    </Stack>
  // </Flex>
  );
}
