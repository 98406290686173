import { Stack, useColorModeValue } from '@chakra-ui/react';
import AUTH_NAV_ITEMS from '@features/Navigation/types/AuthenticatedNavItems';
import PUBLIC_NAV_ITEMS from '@features/Navigation/types/PublicNavItems';
import MobileNavItem from '@features/Navigation/components/MobileNavItem';
import { NavItem } from '@features/Navigation/types/NavItem';
import { useIsAuthenticated } from 'react-auth-kit';

export default function MobileNav() {
  const isAuthenticated = useIsAuthenticated();
  const navItems = isAuthenticated() ? AUTH_NAV_ITEMS : PUBLIC_NAV_ITEMS;
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {
        navItems.map((navItem:NavItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))
      }
    </Stack>
  );
}
