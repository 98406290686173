import { getBrowserVisibilityProp, getIsDocumentHidden } from '@utils/BrowserVisibility';
import { useEffect, useRef } from 'react';

export function usePageIsHidden() {
  const isHidden = useRef(false);
  const onVisibilityChange = () => {
    isHidden.current = getIsDocumentHidden();
  };

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isHidden.current;
}
