import { Button } from '@chakra-ui/react';
import { useLogout } from '@features/auth/api/logout';
import { useSignOut } from 'react-auth-kit';

export default function LogoutButton() {
  const { logoutFn } = useLogout();
  const signOut = useSignOut();
  return (
    <Button
      color="white"
      bgColor="#0061FF"
      onClick={() => {
        signOut();
        logoutFn();
      }}
    >Logout
    </Button>
  );
}
