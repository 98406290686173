import {
  Box,
  Flex,
  IconButton,
  Collapse,
  useColorModeValue,
  useDisclosure, Image, Link, Button,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon, Icon,
} from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logoGMC from '@assets/images/logo.png';
import DesktopNav from '@features/Navigation/components/DesktopNav';
import MobileNav from '@features/Navigation/components/MobileNav';
import LogoutButton from '@features/Navigation/components/LogoutButton';
import { MdOutlineManageAccounts } from 'react-icons/md';
import { useIsAuthenticated } from 'react-auth-kit';
import { useUser } from '@/features/profile/api/getUserById';

function BurgerMenu({ onClick, open }: { onClick: () => void; open: boolean }) {
  return (
    <Flex flex={{ base: 1, md: 'auto' }} ml={-2} display={{ base: 'flex', md: 'none' }}>
      <IconButton
        onClick={onClick}
        icon={open ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
        variant="ghost"
        aria-label="Toggle Navigation"
      />
    </Flex>
  );
}

function NavbarAuthenticatedRightSide() {
  return (
    <>
      <Flex alignItems="center">
        <Link as={RouterLink} to="/app/profile">
          <Icon
            as={MdOutlineManageAccounts}
            height="25px"
            width="25px"
            marginRight="30px"
            color={useColorModeValue('gray.700', 'gray.200')}
          />
        </Link>
        <LogoutButton />
      </Flex>
    </>
  );
}

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const isAuthenticated = useIsAuthenticated();
  const getMe = useUser({});
  const navigate = useNavigate();
  const getMeData = getMe.data!;
  const NavigateBasedOnRole = () => {
    if (getMeData.role === 'SuperAdmin') {
      navigate('/app/campanies/dashboard');
    } else if (getMeData.role === 'CompanyOwner') {
      navigate(`/app/campanies/companyDashboard/${localStorage.getItem('companyId') ?? ''}`);
    } else {
      navigate(`/app/campanies/tests/`);
    }
  };
  return (
    <Box>
      <Flex bg={useColorModeValue('white', 'gray.800')} minH="60px" px={4} align="center">
        <BurgerMenu onClick={onToggle} open={isOpen} />
        <Flex alignItems="center" justify={{ base: 'flex-end', md: 'space-between' }} w="100vw">

          {/* Left Side of navbar */}
          <Flex display={{ base: 'none', md: 'flex' }} gap="20px">
            <Button variant="link" onClick={NavigateBasedOnRole}>
              <Image src={logoGMC} alt="logoGMC" />
            </Button>
            <DesktopNav />
          </Flex>
          {isAuthenticated() && <NavbarAuthenticatedRightSide /> }
        </Flex>

      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}
