import {
  Box,
  Button, useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

export default function MobileNavbar({ onNavClick, activeComponent }: { onNavClick: (component: string) => void; activeComponent: string }) {
  const navigate = useNavigate();
  const { onToggle } = useDisclosure();
  const handleClick = (component: string) => {
    onNavClick(component);
    onToggle();
  };
  return (
    <Box position="fixed" mt={20} left={0} zIndex={1000} ml={5} bgColor="white" w={500} h={500} overflow="hidden">
      <Box display="block" mb={5}>
        <Link
          to="home"
          smooth
          duration={500}
          offset={-60}
          onClick={() => handleClick('Home')}
          activeClass={activeComponent === 'Home' ? 'active' : ''}
          style={{
            color: activeComponent === 'Home' ? '#176FFF' : '#828A92',
            marginRight: '40px',
            marginLeft: '40px',
            fontSize: '18px',
          }}
        >
          Home
        </Link>
      </Box>
      <Box display="block" mb={5}>
        <Link
          to="about"
          smooth
          duration={500}
          offset={-60}
          onClick={() => handleClick('About')}
          activeClass={activeComponent === 'About' ? 'active' : ''}
          style={{
            color: activeComponent === 'About' ? '#176FFF' : '#828A92',
            marginRight: '40px',
            marginLeft: '40px',
            fontSize: '18px',
          }}
        >
          About
        </Link>
      </Box>
      <Box display="block" mb={5}>
        <Link
          to="test"
          smooth
          duration={500}
          offset={-60}
          onClick={() => handleClick('Test')}
          activeClass={activeComponent === 'Test' ? 'active' : ''}
          style={{
            color: activeComponent === 'Test' ? '#176FFF' : '#828A92',
            marginRight: '40px',
            marginLeft: '40px',
            fontSize: '18px',
          }}
        >
          Tests
        </Link>
      </Box>
      <Box display="block" mb={5}>
        <Link
          to="candidate"
          smooth
          duration={500}
          offset={-60}
          onClick={() => handleClick('Candidate')}
          activeClass={activeComponent === 'Candidate' ? 'active' : ''}
          style={{
            color: activeComponent === 'Candidate' ? '#176FFF' : '#828A92',
            marginRight: '40px',
            marginLeft: '40px',
            fontSize: '18px',
          }}
        >
          Candidates
        </Link>
      </Box>
      <Box
        ml={4}
        w="340px"
        h="1px"
        backgroundColor="#EBECF0"
        mr={4}
        mb={10}
      />
      <Box display="block">
        <Link
          to="demo"
          smooth
          duration={500}
          offset={-60}
          onClick={() => handleClick('Demo')}
          style={{
            color: '#176FFF',
            display: 'inline-block',
            width: '292px',
            height: '48px',
            backgroundColor: '#F3F8FF',
            borderRadius: '42px',
            textAlign: 'center',
            lineHeight: '48px',
            marginRight: '18px',
            marginLeft: '40px',
          }}
        >
          Book Demo
        </Link>
      </Box>
      <Box display="block">
        <Button
          fontSize="14px"
          fontWeight="500"
          width="292px"
          height="48px"
          color="white"
          bgColor="#176FFF"
          borderRadius="42px"
          mt={8}
          mr={7}
          ml={10}
          onClick={() => navigate('/auth/login')}
        >
          Log in
        </Button>
      </Box>
    </Box>

  );
}
