import React, { useState, useEffect } from 'react';
import Editor from '@monaco-editor/react';
import { Box } from '@chakra-ui/react';

type CodeEditorWindowProps = {
  onChange: (name: string, value: string) => void;
  language?: string;
  code?: string;
  themeValue?: string;
  height?: string;
  width?: string;
};

export default function CodeEditorWindow({
  onChange,
  language,
  code,
  themeValue,
  height,
  width,
}: CodeEditorWindowProps) {
  const [value, setValue] = useState(code ?? '');

  useEffect(() => {
    setValue(code ?? ''); // Update the value when the code prop changes
  }, [code]);

  const handleEditorChange = (editorValue: string | undefined) => {
    if (typeof editorValue === 'string') {
      setValue(editorValue);
      onChange('code', editorValue); // Notify the parent component about the code change
    }
  };

  return (
    <Box overflow="hidden" w="100%" h="100%" shadow="4xl">
      <Editor
        height={height}
        width={width}
        language={language ?? 'javascript'}
        value={value}
        theme={themeValue}
        onChange={handleEditorChange}
      />
    </Box>
  );
}

CodeEditorWindow.defaultProps = {
  language: 'javascript',
  code: '',
  themeValue: 'Cobalt',
  height: '368px',
  width: '648px',
};
