import dayjs from 'dayjs';
import { TimeSpan } from '@features/tests/types';

export const formatDate = (date: string) => dayjs(date).format('MMMM D, YYYY h:mm A');
export const formatDateHours = (date: string) => dayjs(date).format('h:mm A');
export const formatMonth = (date: string) => dayjs(date).format('MMMM YYYY');
export const formatHours = (date: string) => dayjs(date).format('MMMM D, YYYY HH:mm:ss');

export const formatSeconds = (seconds :number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const formatToMMSS = (seconds :number) => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const formatToHHMMSS = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const remainingSeconds2 = remainingSeconds % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds2.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const formatTimeSpan = (timeSpan: string): string => {
  const [hhmmss] = timeSpan.split('.');
  const formattedTime = formatToHHMMSS(Number(hhmmss.replace(/:/g, '')));
  return formattedTime;
};
export const formatTimeSpanToMMSS = (timeSpan: TimeSpan): string => formatToMMSS(timeSpan.totalSeconds);

export const formatTimeDuration = (timeDuration: any) => {
  const parts = timeDuration?.split(':');
  const hours = parts[0].padStart(2, '0');
  const minutes = parts[1].padStart(2, '0');
  const secondsWithMilliseconds = parts[2].split('.');
  const seconds = secondsWithMilliseconds[0].padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};
