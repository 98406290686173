/* eslint-disable import/no-extraneous-dependencies */
import {
  Box,
  Flex,
  useColorModeValue, Image, Button, useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-scroll';
import logoGMC from '@assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function DesktopNavbar({ onNavClick, activeComponent }: { onNavClick: (component: string) => void; activeComponent: string }) {
  const { onToggle } = useDisclosure();
  const [isFixed, setIsFixed] = useState(false);
  const [activeLink, setActiveLink] = useState(activeComponent);
  const navigate = useNavigate();
  const handleClick = (component: string) => {
    onNavClick(component);
    onToggle();
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const homeSection = document.getElementById('home');
    const aboutSection = document.getElementById('about');
    const testSection = document.getElementById('test');
    const candidateSection = document.getElementById('candidate');
    const demoSection = document.getElementById('demo');

    if (homeSection && scrollPosition < homeSection.offsetTop) {
      setActiveLink('Home');
    } else if (aboutSection && scrollPosition >= aboutSection.offsetTop && testSection && scrollPosition < testSection.offsetTop) {
      setActiveLink('About');
    } else if (testSection && scrollPosition >= testSection.offsetTop && candidateSection && scrollPosition < candidateSection.offsetTop) {
      setActiveLink('Test');
    } else if (candidateSection && scrollPosition >= candidateSection.offsetTop && demoSection && scrollPosition < demoSection.offsetTop) {
      setActiveLink('Candidate');
    } else if (demoSection && scrollPosition >= demoSection.offsetTop) {
      setActiveLink('Demo');
    } else {
      setActiveLink('Home');
    }
    setIsFixed(scrollPosition > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box position={isFixed ? 'fixed' : 'static'} top={0} left={0} right={0} zIndex={999}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        height="100%"
        minWidth="100%"
        justifyContent="center"
      >
        <Flex alignItems="center">
          <Link to="home" smooth duration={500} offset={-60}>
            <Image
              src={logoGMC}
              ml={[-10, -10, -5, -100, 100, -30, -100, -100]}
              mr={['0', '0', '10', '-60px', '250px', '100px', '0']}
              mt={10}
              alt="logoGMC"
              maxWidth={['100%', '100%', '150px', '170px', '200px', '400px', '400px', '400px']}
            />
          </Link>
          <Flex
            ml={[-20, '150px', -15, '150px', '-20px', '570px', '700px']}
            mt={10}
            mr={['0', '0', '50', '150px', '50px', '50']}
            alignItems="center"
          >
            <Link
              to="home"
              smooth
              duration={500}
              offset={-60}
              onClick={() => handleClick('Home')}
              activeClass={activeComponent === 'Home' ? 'active' : ''}
              style={{
                color: activeLink === 'Home' ? '#176FFF' : '',
                textDecorationColor: activeLink === 'Home' ? '#176FFF' : '',
                borderBottom: activeLink === 'Home' ? '2px solid #176FFF' : '',
                marginRight: '30px',
              }}
            >
              Home
            </Link>
            <Link
              to="about"
              smooth
              duration={500}
              offset={-60}
              onClick={() => handleClick('About')}
              activeClass={activeComponent === 'About' ? 'active' : ''}
              style={{
                color: activeLink === 'About' ? '#176FFF' : '',
                textDecorationColor: activeLink === 'About' ? '#176FFF' : '',
                borderBottom: activeLink === 'About' ? '2px solid #176FFF' : '',
                marginRight: '30px',
              }}
            >
              About
            </Link>
            <Link
              to="test"
              smooth
              duration={500}
              offset={-60}
              onClick={() => handleClick('Test')}
              activeClass={activeComponent === 'Test' ? 'active' : ''}
              style={{
                color: activeLink === 'Test' ? '#176FFF' : '',
                textDecorationColor: activeLink === 'Test' ? '#176FFF' : '',
                borderBottom: activeLink === 'Test' ? '2px solid #176FFF' : '',
                marginRight: '30px',
              }}
            >
              Tests
            </Link>
            <Link
              to="candidate"
              smooth
              duration={500}
              offset={-60}
              onClick={() => handleClick('Candidate')}
              activeClass={activeComponent === 'Candidate' ? 'active' : ''}
              style={{
                color: activeLink === 'Candidate' ? '#176FFF' : '',
                textDecorationColor: activeLink === 'Candidate' ? '#176FFF' : '',
                borderBottom: activeLink === 'Candidate' ? '2px solid #176FFF' : '',
                marginRight: '30px',
              }}
            >
              Candidates
            </Link>
          </Flex>
          <Flex
            ml={[-20, -50, -90, -40, 'auto']}
            mr={['0', '0', '0', '0', '0']}
            mt={10}
            alignItems="center"
          >
            <Link
              to="demo"
              smooth
              duration={500}
              offset={-60}
              onClick={() => handleClick('Demo')}
              style={{
                color: '#176FFF',
                display: 'inline-block',
                width: '124px',
                height: '48px',
                backgroundColor: '#F3F8FF',
                borderRadius: '42px',
                textAlign: 'center',
                lineHeight: '48px',
                marginLeft: '10px',
                marginRight: '10px',
                cursor: 'pointer',
              }}
            >
              Book Demo
            </Link>
            <Button
              fontSize="14px"
              fontWeight="500"
              width="124px"
              height="48px"
              color="white"
              bgColor="#176FFF"
              borderRadius="42px"
              onClick={() => navigate('/auth/login')}
            >
              Log in
            </Button>
          </Flex>
        </Flex>
      </Flex>

    </Box>

  );
}
