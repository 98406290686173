import { FormControl, FormLabel } from '@chakra-ui/react';
import CodeEditorWindow from '@components/CodeEditorWindow';
import { SetStateAction } from 'react';

type CodeEditorInputProps = {
  label?: string,
  width?: string,
  height?: string,
  onCodeChange: (action: any, data: SetStateAction<string>) => void,
  code: string,
  theme: { value: string, label: string }
  language: string
};
export default function CodeEditorInput({
  label, onCodeChange, code, theme, language, width, height,
}: CodeEditorInputProps) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <CodeEditorWindow onChange={onCodeChange} code={code} themeValue={theme.value} language={language} width={width} height={height} />
    </FormControl>
  );
}

CodeEditorInput.defaultProps = {
  label: '',
  width: '348px',
  height: '368px',
};
