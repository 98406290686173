/* eslint-disable max-len */
import {
  Box,
  // Button,
  Flex,
  // FormControl,
  // FormLabel,
  // Input,
  // Select,
  Stack, Text, useMediaQuery,
} from '@chakra-ui/react';
import Footer from './Footer';
import HubSpotForm from './HubspotForm';

export default function BookDemo() {
  const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');
  return (
    <Flex
      height="100%"
      minWidth="100vw"
      justifyContent="center"
    >
      <Stack>
        <Box
          justifyContent="center"
          mt={isSmallerThan768 ? '190px' : '250px'}
          ml={isSmallerThan768 ? '1520px' : '-45px'}
        >
          <Flex justifyContent="center" flexDirection="column" alignItems="center">
            <Text
              fontSize={isSmallerThan768 ? '18px' : '21px'}
              fontWeight="600"
              color="#000000"
              textTransform="capitalize"
              w={isSmallerThan768 ? '312px' : ''}
              ml={['-1500', '-1500', '1', '']}
            >unlock
              our future: The ultimate hiring platform awaits you!
            </Text>
            <Text
              fontSize={isSmallerThan768 ? '9px' : '13px'}
              fontWeight="400"
              color="#6B6B6B"
              w={isSmallerThan768 ? '304px' : ''}
              mt={isSmallerThan768 ? '4' : '0'}
              ml={['-1500', '-1500', '1', '']}
            >you can experience the power of our
              platform firsthand and see how it can help you take your
            </Text>
            <Text
              fontSize={isSmallerThan768 ? '9px' : '13px'}
              fontWeight="400"
              color="#6B6B6B"
              w={isSmallerThan768 ? '304px' : ''}
              ml={['-1500', '-1500', '1', '']}
            >hiring to the next level.
            </Text>
          </Flex>
        </Box>
        <Flex justifyContent="center" alignItems="center">
          <Box
            w={['200px', '300px', '650px', '900px']}
            minH={isSmallerThan768 ? '550px' : '564.04px'}
            border="1px solid #EBECF0"
            borderRadius="43px"
            transform="matrix(1, 0, -0.01, 1, 0, 0)"
            ml={['-2', '-15', '-10', '-5']}
            mt="80px"
            position="relative"
          >
            <Text
              fontSize={isSmallerThan768 ? '14px' : '19px'}
              fontWeight="500"
              color="#176FFF"
              position="absolute"
              mt={isSmallerThan768 ? '-3' : '-15px'}
              ml={['37px', '90', '260', '390px']}
              bg="#fff"
            >Book A Demo Day
            </Text>
            <HubSpotForm />

          </Box>
        </Flex>
        <Flex>
          <Box mt="750px">
            <Footer />
          </Box>
        </Flex>
      </Stack>
    </Flex>
  );
}
