import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FaEyeSlash, FaEye,
} from 'react-icons/fa';
import { useLogin } from '@features/auth';
import { useIsAuthenticated } from 'react-auth-kit';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Mixpanel } from '@/mixpanel';

type LoginInputs = {
  email: string,
  password: string,
};

interface LoginFormProps {
  onSuccess: (role: string) => void;
}

export default function LoginForm({ onSuccess }: LoginFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const useLoginMutation = useLogin();
  const isAuthenticated = useIsAuthenticated();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginInputs>();

  // If the user is already authenticated, redirect them to the dashboard
  if (isAuthenticated()) {
    onSuccess('user');
  }
  const onSubmit: SubmitHandler<LoginInputs> = async (data) => {
    try {
      Mixpanel.identify(data.email);

      Mixpanel.track('Login', {
        email: data.email,
        role: 'user',
      });

      const response = await useLoginMutation.mutateAsync(data);

      if (response && response.user && response.user.role) {
        Mixpanel.track('Login Success', { role: response.user.role });
        Mixpanel.identify({ firstName: response.user.firstName, lastName: response.user.lastName });
        Mixpanel.people.set({
          $email: response.user.email,
          $firstName: response.user.firstName,
          $lastName: response.user.lastName,
          $role: response.user.role,
        });
        onSuccess(response.user.role);
      } else {
        setErrorMessage('Invalid response from the server');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage1 = error.response?.data || 'Something went wrong';
        setErrorMessage(errorMessage1);
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    }
  };
  const handleShowClick = () => setShowPassword(!showPassword);
  return (
    <Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          backgroundColor="whiredpha.900"
          spacing={7}
          direction="column"
          color="black"
          maxW={500}
          mx="auto"
          px={4}
        >
          <FormLabel htmlFor="email" color="#828A92" mb="-10px" fontSize="14px">
            Email
          </FormLabel>
          <Input
            id="email"
            {...register('email')}
            backgroundColor="white"
            borderRadius="10px"
            placeholder="Email"
            width="100%"
            border="1px solid #EBECF0"
          />

          <FormLabel htmlFor="password" color="#828A92" mb="-10px" fontSize="14px">
            Password
          </FormLabel>
          <FormControl
            isInvalid={Boolean(errors.password?.message)}
            backgroundColor="white"
            borderRadius="md"
          >
            <Box position="relative" width="100%">
              <InputGroup>
                <Input
                  id="password"
                  mt={-2}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  {...register('password')}
                  borderRadius="10px"
                  width="100%"
                  border="1px solid #EBECF0"
                  pr="4.5rem"
                  _placeholder={{ marginLeft: '-10px' }}
                  sx={{
                    '&::-ms-reveal': { display: 'none' },
                  }}
                />
                <Box
                  position="absolute"
                  right="0"
                  top="50%"
                  transform="translateY(-50%)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={2}
                >
                  <Button
                    bgColor="transparent"
                    color="#828A92"
                    h="1.75rem"
                    size="sm"
                    onClick={handleShowClick}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                </Box>
              </InputGroup>
              {errorMessage && <Text color="red" mt={2}>{errorMessage}</Text>}
            </Box>
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>

          <Flex alignItems="center">
            <Checkbox
              defaultChecked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              color="#828A92"
              mr={2}
            >
              Remember me
            </Checkbox>

            <Link
              to="/auth/forgotPassword"
              style={{
                fontWeight: '500',
                color: '#333333',
                fontSize: '14px',
                marginLeft: 'auto',
              }}
            >
              Forgot password?
            </Link>
          </Flex>

          <Button
            borderRadius={8}
            type="submit"
            fontWeight={400}
            bg="#176FFF"
            color="white"
            width="100%"
            isLoading={useLoginMutation.isLoading}
          >
            Sign In
          </Button>
        </Stack>
      </form>
    </Stack>

  );
}
