import {
  Box, Flex, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AssessmentDTO, AssignmentDTO } from '@features/candidateTest/types';
import { TbCircleDotted } from 'react-icons/tb';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';

type PropTypes = {
  assessmentBridgePage: boolean
};
export default function StartedTestHeader({ assessmentBridgePage } : PropTypes) {
  const { UniqueTestId } = useParams();
  const queryClient = useQueryClient();
  const testInfoData = queryClient.getQueryData(['testInfo', UniqueTestId]) as AssignmentDTO;
  const currentAssessment = queryClient.getQueryData(['currentAssessment']) as AssessmentDTO;
  const assessmentsArray = testInfoData?.assignedTest.test.assessments;
  const [sliderValue, setSliderValue] = useState(0);
  useEffect(() => {
    if (!assessmentBridgePage) setSliderValue(currentAssessment.order * (100 / (assessmentsArray.length - 1)));
    else setSliderValue((currentAssessment.order + 1) * (100 / (assessmentsArray.length - 1)));
  }, [assessmentBridgePage, currentAssessment, assessmentsArray]);

  return (
    <Box pt={6} pb={2} pos="absolute" top="0" width="50vw" left="25vw">
      <Slider
        isReadOnly
        value={sliderValue}
        position="relative"
      >

        {assessmentsArray.map((assessment, index) => (
          <SliderMark
            key={assessment.id}
            value={index * (100 / (assessmentsArray.length - 1))}
            style={{
              position: 'absolute',
              top: '0px',
              // left: `${index * (100 / (assessmentsArray.length - 1))}%`,
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
            textTransform="capitalize"
            zIndex="1"
          >
            <Flex direction="column" alignItems="center">
              {(() => {
                if (index === currentAssessment.order) {
                  return (
                    <Box boxSize={6} borderRadius={20} mb={1} bg="transparent" />
                  );
                } if (index < currentAssessment.order) {
                  return (
                    <Box boxSize={6} mb={1}><CheckCircleIcon
                      boxSize={6}
                      bg="#F6F6F6"
                      color={!assessmentBridgePage ? '#C3C3C3' : '#9747FF'}
                    />
                    </Box>
                  );
                }
                return (
                  <Box boxSize={6} borderRadius={20} mb={1} bg="#e7eaf4" />
                );
              })()}

              <Text whiteSpace="nowrap">{assessment.title}</Text>
            </Flex>

          </SliderMark>
        ))}
        {!assessmentBridgePage
          ? (
            <>
              <SliderTrack h="10px">
                <SliderFilledTrack
                  bg="#C3C3C3"
                />
              </SliderTrack>
              <SliderThumb boxSize={6}>
                <Box
                  as={TbCircleDotted}
                  color="#C3C3C3"
                  boxSize={6}
                />
              </SliderThumb>
            </>
          ) : (
            <>
              <SliderTrack h="10px">
                <SliderFilledTrack
                  bg="#9747FF"
                />
              </SliderTrack>
              <SliderThumb boxSize={6}>
                <Box
                  as={TbCircleDotted}
                  color="#9747FF"
                  boxSize={6}
                />
              </SliderThumb>
            </>

          )}

      </Slider>
    </Box>
  );
}
