import { ButtonGroup } from '@chakra-ui/react';
import CustomButton from '@features/candidateTest/components/Buttons/CustomButton';
import { Dispatch, SetStateAction } from 'react';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';
type PropTypes = {
  currentQuestionIdx: number;
  setCurrentQuestionIdx: Dispatch<SetStateAction<number>>;
  questionsArrayLength: number;
  isLoading: boolean;
  [x:string] : any;
};
export default function TwoButtonGroup({
  currentQuestionIdx, setCurrentQuestionIdx, questionsArrayLength, isLoading, ...rest
}: PropTypes) {
  const { t } = useTranslation(nameSpaces.candidateInstructions);
  return (
    <ButtonGroup {...rest}>
      <CustomButton
        label={t('questionsButtons.previousButton')}
        onClick={() => currentQuestionIdx > 0 && setCurrentQuestionIdx((curr) => curr - 1)}
        variant="outlinePrevious"
        isDisabled={currentQuestionIdx === 0}
      />
      <CustomButton
        key={currentQuestionIdx}
        variant="solidIcon"
        type={currentQuestionIdx === questionsArrayLength - 1 ? 'submit' : 'button'}
        label={currentQuestionIdx === questionsArrayLength - 1 ? `${t('questionsButtons.submitAnswers')}` : `${t('questionsButtons.nextButton')}`}
        onClick={() => currentQuestionIdx < questionsArrayLength - 1 && setCurrentQuestionIdx((curr) => curr + 1)}
        isLoading={isLoading}
      />
    </ButtonGroup>
  );
}

TwoButtonGroup.defaultProps = {
};
