import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Image,
  Box,
  Text,
} from '@chakra-ui/react';
import YellowBox from '@assets/images/yellowBox.png';
import Box2 from '@assets/images/box2.png';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';
interface CopyPasteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function CopyPasteModal({ isOpen, onClose, onConfirm }: CopyPasteModalProps) {
  const { t } = useTranslation(nameSpaces.candidateInstructions);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="29px" w="100%">
        <ModalHeader p={0} borderBottom="none" position="relative">
          <Image src={YellowBox} alt="Header Image" w="100%" h="100%" objectFit="cover" />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={1}
          >
            <Image src={Box2} alt="Box2 Image" />
          </Box>
        </ModalHeader>
        <ModalBody textAlign="center">
          <Box mb="20px">
            <Text fontSize="22px" fontWeight="600" color="#333333" mt={10}>{t('copyPasteModal.notAllowed')}</Text>
          </Box>
          <Text fontSize="14px" fontWeight="400" color="#333333" mt={6}>
          {t('copyPasteModal.restricted')}
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#333333">{t('copyPasteModal.thankYou')}</Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            color="white"
            bgColor="#333333"
            w="294px"
            h="50px"
            borderRadius={8}
            fontSize="14px"
            fontWeight="500"
            onClick={onConfirm}
          >
            {t('copyPasteModal.resumeButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CopyPasteModal;
