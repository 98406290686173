import React, { Suspense } from 'react';
import FullPageSpinner from '@components/FullPageSpinner';
import { Outlet } from 'react-router-dom';
import CandidateWorkflowLayout from '@components/Layout/CandidateWorkflowLayout';
import { Login } from '@features/auth/pages/Login';
import StartTestRoutes from '@/features/candidateTest/routes';
import LandingPage from '@/features/landingPage/components/LandingPage';

const AuthRoutes = React.lazy(() => import('@features/auth/routes'));

function PublicPagesWrapper() {
  return (
    <CandidateWorkflowLayout>
      <Suspense
        fallback={(
          <FullPageSpinner />
            )}
      >
        <Outlet />
      </Suspense>
    </CandidateWorkflowLayout>
  );
}
export default [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/app/*',
    element: <Login />,
    exact: true,
  },
  {
    path: '/',
    element: <LandingPage />,
    exact: true,
  },
  {
    path: '/*',
    element: <PublicPagesWrapper />,
    children: [
      {
        path: '*',
        element: <StartTestRoutes />,
      },
    ],
  },

];
