/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Button,
  Flex, Image, Stack, Text, useMediaQuery,
} from '@chakra-ui/react';
import invitedToTest from '@features/landingPage/components/assets/images/candidates/invitedToTest.png';
import testsSent from '@features/landingPage/components/assets/images/candidates/testsSent.png';
import hired from '@features/landingPage/components/assets/images/candidates/hired.png';
import candidateScore from '@features/landingPage/components/assets/images/candidates/candidateScore.png';
import { useEffect, useRef, useState } from 'react';
// import Testimonials from './Testimonials';

export default function Candidates(props: { onGetNextComponentClick: () => void }) {
  const showText = useRef(null);
  const invitedToTestRef = useRef(null);
  const testsSentRef = useRef(null);
  const hiredRef = useRef(null);
  const candidateScoreRef = useRef(null);

  const [showText1, setShowText] = useState(false);
  const [showImage1, setShowImage1] = useState(false);
  const [showImage2, setShowImage2] = useState(false);
  const [showImage3, setShowImage3] = useState(false);
  const [showImage4, setShowImage4] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            switch (entry.target.id) {
              case 'showText':
                setShowText(true);
                break;
              case 'invitedToTest':
                setShowImage1(true);
                break;
              case 'testsSent':
                setShowImage2(true);
                break;
              case 'hired':
                setShowImage3(true);
                break;
              case 'candidateScore':
                setShowImage4(true);
                break;
              default:
                break;
            }
          }
        });
      },
      { threshold: 0.5 },
    );

    if (showText.current) {
      observer.observe(showText.current);
    }
    if (invitedToTestRef.current) {
      observer.observe(invitedToTestRef.current);
    }
    if (testsSentRef.current) {
      observer.observe(testsSentRef.current);
    }
    if (hiredRef.current) {
      observer.observe(hiredRef.current);
    }
    if (candidateScoreRef.current) {
      observer.observe(candidateScoreRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);
  const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');
  return (
    <Flex
      height="100%"
      minWidth="100vw"
      justifyContent="center"
    >
      <Stack>
        <Flex mt="200px">
          <Box
            mt={isSmallerThan768 ? '-190px' : '110px'}
            ml={['-130', '-150', '50', '50', '-30', '-350', '-650']}
            maxWidth={['220', '300', '100%']}
            height="132px"
            id="showText"
            ref={showText}
            opacity={showText1 ? 1 : 0}
            transform={showText1 ? 'translateX(0)' : 'translateX(-50px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            <Text fontSize={['18', '20', '20', '29', '31']} fontWeight="300" color="#000000">Discover the Top Talent.</Text>
            <Text fontSize={['18', '20', '20', '29', '31']} fontWeight="500" color="#000000">Check Candidate Score</Text>
            <Text fontSize={['18', '20', '20', '29', '31']} fontWeight="500" color="#000000">and See Who&apos;s Hired!</Text>
            <Text fontSize={['9', '11', '10', '13']} fontWeight="400" color="#6B6B6B" mt={4}>By checking the Candidate Score, you can make informed</Text>
            <Text fontSize={['9', '11', '10', '13']} fontWeight="400" color="#6B6B6B">decisions and prioritize your outreach to the best candidates. With</Text>
            <Text fontSize={['9', '11', '10', '13']} fontWeight="400" color="#6B6B6B">our platform, you&apos;ll have access to a wealth of information that</Text>
            <Text fontSize={['9', '11', '10', '13']} fontWeight="400" color="#6B6B6B">can help you find the right talent faster and with more confidence.</Text>
            <Flex mt={6}>
              <Button
                fontSize="15px"
                fontWeight="400"
                color="white"
                bgColor="#176FFF"
                width="168px"
                height="60px"
                borderRadius="42px"
                onClick={props.onGetNextComponentClick}
              >Book a Demo
              </Button>
            </Flex>
          </Box>
          <Box
            w={isSmallerThan768 ? '296.62px' : '430.18px'}
            h={{ base: '289.6px', md: '420px', lg: '420px' }}
            bgGradient="radial-gradient(80.9% 80.9% at 54.57% 19.1%, rgba(255, 255, 255, 0.1) 0%,
             rgba(183, 194, 211, 0.5) 52.91%, rgba(23, 111, 255, 0.1) 100%)"
            borderRadius="50%"
            filter="blur(50px)"
            transform="rotate(-17.23deg)"
            flex="1"
            ml={{ base: '-800px', md: '100px', lg: '290px' }}
            position="relative"
            mt={isSmallerThan768 ? '-5px' : ''}
          />
          <Flex
            ref={invitedToTestRef}
            id="invitedToTest"
            position="absolute"
            transform={`translate(${showImage1 ? '1000px' : '-50px'}, ${showImage1 ? '0' : '-50px'})`}
            transition="transform 0.5s ease-out"
            ml={['-1020', '-1100', '-500', '-450', '-400', '-300', '-200']}
            mt={isSmallerThan768 ? '150px' : ''}
          >
            {showImage1 && <Image src={invitedToTest} w={['150px', '150px', '250px', '250px', '400px', '', '']} />}
          </Flex>
          <Flex
            ref={testsSentRef}
            id="testsSent"
            position="absolute"
            transform={`translate(${showImage2 ? '1140px' : '-50px'}, ${showImage2 ? '90px' : '-50px'})`}
            transition="transform 0.5s ease-out"
            ml={['-1250', '-1150', '-550', '-450', '-300', '-300', '-200']}
            mt={isSmallerThan768 ? '110px' : ''}
          >
            {showImage2 && <Image src={testsSent} w={['150px', '150px', '150px', '250px', '320px', '', '']} />}
          </Flex>
          <Flex
            ref={hiredRef}
            id="hired"
            position="absolute"
            transform={`translate(${showImage3 ? '800px' : '-50px'}, ${showImage3 ? '150px' : '-50px'})`}
            transition="transform 0.5s ease-out"
            ml={['-920', '-970', '-370', '-370', '-300', '-300', '-200']}
            mt={isSmallerThan768 ? '130px' : ''}
          >
            {showImage3 && <Image src={hired} w={['150px', '150px', '150px', '250px', '320px', '', '']} />}
          </Flex>
          <Flex
            ref={candidateScoreRef}
            id="candidateScore"
            position="absolute"
            transform={`translate(${showImage4 ? '1030px' : '-50px'}, ${showImage4 ? '310px' : '-50px'})`}
            transition="transform 0.5s ease-out"
            ml={['-1070', '-1040', '-450', '-450', '-300', '-300', '-200']}
            mt={['10', '-5', '-20', '0', '', '', '']}
          >
            {showImage4 && <Image src={candidateScore} w={['150px', '150px', '150px', '250px', '320px', '', '']} />}
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  );
}
