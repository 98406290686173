import { FormControl, Grid } from '@chakra-ui/react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import CustomCheckpoint from '@components/CustomCheckbox';

type CheckpointOptions = {
  id: string;
  statement: string;
};
type CheckpointInputProps = {
  label: string,
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  fieldName: string;
  options: CheckpointOptions[];
};

export default function CheckpointInputCandidate({
  label, options, getValues, setValue, fieldName,
}: CheckpointInputProps) {
  return (
    <FormControl mr={2} key={label}>
      <Grid
        padding="10px"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(2, 1fr)"
        gap={10}
      >
        {options.map((option) => (
          <CustomCheckpoint
            key={option.id}
            option={option}
            onChange={() => setValue(
              fieldName,
              // eslint-disable-next-line no-nested-ternary
              getValues(fieldName) && getValues(fieldName).includes(option.id.toString())
                ? getValues(fieldName).filter((id: string) => id.toString() !== option.id.toString())
                : getValues(fieldName)
                  ? [...getValues(fieldName), option.id.toString()]
                  : [option.id.toString()],
            )}
          />
        ))}
      </Grid>
    </FormControl>
  );
}
