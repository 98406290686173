import {
  Box, Flex, Text,
} from '@chakra-ui/react';
import { singleAssessmentDetails } from '@features/candidateTest/types';
import { BsFileEarmarkCheckFill } from 'react-icons/bs';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';

export default function AssessmentDetailsCard({ assessment } : { assessment: singleAssessmentDetails }) {
  const { t } = useTranslation(nameSpaces.candidateInstructions);

  return (
    <Flex
      justifyContent="space-evenly"
      alignItems="center"
      backgroundColor="#FAF6FF"
      padding={10}
      rounded="2xl"
      gap={10}
      minW="60vw"
    >
      <Flex
        gap={5}
      >
        <BsFileEarmarkCheckFill
          size="55px"
          color="#9747FF"
        />
        <Flex flexDirection="column">
          <Text
            color="#9747FF"
            fontSize="xl"
            fontWeight="bold"
          >
            {assessment && assessment?.title}
          </Text>
          <Text
            color="#818181"
            fontSize="sm"
            fontWeight="normal"
          >
            {t('successfullySaved')}
          </Text>
        </Flex>
      </Flex>
      <Box
        height="60px"
        width="1px"
        backgroundColor="#9747FF"
      />
      <Flex
        gap={5}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Text as="span" color="#9747FF" fontSize="20px" fontWeight="bold">{assessment?.totalAnswers}</Text>
            <Text as="span" color="#818181" fontSize="14px" fontWeight="normal">/{assessment?.numberOfQuestions}</Text>
          </Box>
          <Text color="#8A8A8A">{t('totalAnswers')}</Text>
        </Flex>
        {/* <Flex */}
        {/*  flexDirection="column" */}
        {/*  justifyContent="center" */}
        {/*  alignItems="center" */}
        {/* > */}
        {/*  <Box> */}
        {/*    <Text as="span" color="#9747FF" fontSize="20px" fontWeight="bold">{formatTimeSpanToMMSS(assessment.timeSpentOnAssessment)}</Text> */}
        {/*    <Text as="span" color="#818181" fontSize="14px" fontWeight="normal">/{formatTimeSpanToMMSS(assessment.assessmentDuration)}</Text> */}
        {/*  </Box> */}
        {/*  <Text color="#8A8A8A">Time spent</Text> */}
        {/* </Flex> */}
      </Flex>
    </Flex>
  );
}
