import {
  Box, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Flex, Text, useDisclosure, Fade,
} from '@chakra-ui/react';
import RulerIcon from '@assets/images/RulerIcon.png';
import BeforeYouStartCard from '@features/candidateTest/components/BeforeYouStartCard';
import { CgTimer } from 'react-icons/cg';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { AssignmentDTO } from '@features/candidateTest/types';
import { formatToHHMMSS } from '@utils/format';
import { AiOutlineStop } from 'react-icons/ai';
import { CiFileOff } from 'react-icons/ci';
import { BsFillCalculatorFill } from 'react-icons/bs';
import CustomButton from '@features/candidateTest/components/Buttons/CustomButton';
import axios from '@lib/axios';
import CountdownTimer from '@components/CountdownTimer';
import Timer from '@assets/images/timer.png';
import Play from '@assets/images/play.png';
import Ban from '@assets/images/ban.png';
import Code from '@assets/images/code.png';
import PaperPlane from '@assets/images/paper-plane.png';
import CodingTestRulesCard from './CodingTestRulesCard';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

type PropTypes = {
  toggleRulesPage: () => void;
  onBack: () => void;
  language?: string;
};

export default function BeforeYouStart({ toggleRulesPage, onBack, language }: PropTypes) {
  const { UniqueTestId } = useParams();
  const queryClient = useQueryClient();
  const assignment : AssignmentDTO = queryClient.getQueryData(['testInfo', UniqueTestId])!;
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const { t, i18n } = useTranslation(nameSpaces.candidateInstructions);
  let totalDuration = 0;
  if (assignment && assignment?.assignedTest && assignment?.assignedTest?.test) {
    const assessments = assignment?.assignedTest?.test?.assessments || [];
    assessments?.forEach((assessment) => {
      totalDuration += assessment?.duration;
    });
  }
  // detect language change
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const startButtonHandler = async () => {
    if (document.documentElement.requestFullscreen) {
      try {
        await document.documentElement.requestFullscreen();
        onToggle();
        setTimeout(async () => {
          await axios.post(`/CandidateTest/StartTest/${UniqueTestId}`);
          navigate('start');
        }, 3000);
      } catch (error) {
        //
      }
    } else {
      //
    }
  };

  let hasCodingType = false;
  assignment?.assignedTest?.test?.assessments?.forEach((assessment) => {
    if (assessment?.questionsTypes?.includes('Coding')) {
      hasCodingType = true;
    }
  });

  return (
    isOpen
      ? (
        <Fade in={isOpen}>
          <Box
            rounded="2xl"
            bgColor={hasCodingType ? '#101012' : 'white'}
            minH="100vh"
            width="100vw"
            marginY="auto"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="0"
            left="0"
          >
            <Flex
              fontSize="2xl"
              fontWeight="semibold"
              h="100vh"
              color="#9747FF"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text>
              {t('getReady')}
              </Text>
              <CountdownTimer />
            </Flex>
          </Box>
        </Fade>
      )
      : (
        <Box
          rounded="2xl"
          bgColor="white"
          marginY={10}
          marginX={20}
          position="relative"
          minH="70vh"
          _after={{
            content: '""',
            position: 'absolute',
            top: '-27px',
            left: '50%',
            width: '57px',
            height: '57px',
            backgroundImage: `url(${RulerIcon})`,
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Card
            align="center"
            gap={10}
            boxShadow="none"
            rounded="2xl"
          >
            <CardHeader mt={10}>
              {hasCodingType ? (
                <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Flex flexDirection="column" alignItems="flex-start" mr={280}>
                    <Box fontSize="2xl">
                      <Text as="span" fontWeight="medium"> {t('beforeYouStart')} </Text>
                      <Text as="span" fontWeight="bold">{t('theseRoles')}</Text>
                    </Box>
                    <Text fontSize="sm" as="h4" color="#A55AFF" fontWeight="medium">
                    {t('onceYouStart')}
                    </Text>
                  </Flex>
                  <ButtonGroup>
                    <CustomButton
                      label={t('instructions.backButton')}
                      onClick={() => {
                        toggleRulesPage();
                        onBack();
                      }}
                      variant="ghost"
                    />
                    <CustomButton
                      label={t('instructions.startTestButton')}
                      onClick={startButtonHandler}
                      variant="solidIcon"
                    />
                  </ButtonGroup>
                </Flex>
              ) : (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box fontSize="2xl">
                    <Text as="span" fontWeight="medium"> {t('beforeYouStart')} </Text>
                    <Text as="span" fontWeight="bold">{t('theseRoles')}</Text>
                  </Box>
                  <Text fontSize="sm" as="h4" color="#A55AFF" fontWeight="medium">
                  {t('onceYouStart')}
                  </Text>
                </Flex>
              )}
            </CardHeader>
            <CardBody>
              <Flex gap={10} fontWeight="semi-bold" marginX={20} flexWrap="wrap">
                {!hasCodingType ? (
                  <>
                    <BeforeYouStartCard
                      Icon={CgTimer}
                      label={t('instructions.timeToComplete', {duration: `${formatToHHMMSS(totalDuration)}`} )}
                      flex={1}
                    />
                    <BeforeYouStartCard
                      Icon={AiOutlineStop}
                      label={t('instructions.completeInOneSitting')}
                      flex={1}
                    />
                    <BeforeYouStartCard
                      Icon={CiFileOff}
                      label={t('instructions.noExternalResources')}
                      flex={1}
                    />
                    <BeforeYouStartCard
                      Icon={BsFillCalculatorFill}
                      label={t('instructions.mayUse')}
                      flex={1}
                    />
                  </>
                ) : (
                  <>
                    <CodingTestRulesCard
                      label={t('instructions.timeLimit')}
                      text={t('instructions.codingTest', {duration: `${formatToHHMMSS(totalDuration)}`} )}
                      image={Timer}
                    />
                    <CodingTestRulesCard
                      label={t('instructions.testCases')}
                      text={t('instructions.testYourSolution')}
                      image={Play}
                    />
                    <CodingTestRulesCard
                      label={t('instructions.language')}
                      text={t('instructions.chooseProgrammingLanguage')}
                      image={Code}
                    />
                    <CodingTestRulesCard
                      label={t('instructions.externalResources')}
                      text={t('instructions.doNotUse')}
                      image={Ban}
                    />
                    <CodingTestRulesCard
                      label={t('instructions.submissionProcess')}
                      text={t('instructions.mustComplete')}
                      image={PaperPlane}
                    />
                    <CodingTestRulesCard
                      label={t('instructions.problemStatement')}
                      text={t('instructions.readProblemStatement')}
                      image={PaperPlane}
                    />
                  </>
                )}
              </Flex>
            </CardBody>
            {!hasCodingType && (
            <CardFooter
              borderBottom="none"
              marginBottom={10}
            >
              <ButtonGroup>
                <CustomButton
                  label={t('instructions.backButton')}
                  onClick={() => {
                    toggleRulesPage();
                    onBack();
                  }}
                  variant="ghost"
                />
                <CustomButton
                  label={t('instructions.startTestButton')}
                  onClick={startButtonHandler}
                  variant="solidIcon"
                />
              </ButtonGroup>
            </CardFooter>
            )}
          </Card>
        </Box>
      )
  );
}
