import React from 'react';
import {
  Box,
  Flex, Text, Image, Button,
} from '@chakra-ui/react';
import ErrorImage from '@assets/images/error.jpg';

interface ICustomErrorRequiredProps {
  title: string;
  description: string;
}
interface ICustomErrorOptionalProps {
  onRefreshClick?: () => void;
  hasRefreshButton?:boolean;
}

const defaultProps: ICustomErrorOptionalProps = {
  hasRefreshButton: false,
  onRefreshClick: () => {},
};
interface CustomErrorProps extends ICustomErrorRequiredProps, ICustomErrorOptionalProps {}

export default function CustomErrorPage({
  title, description, onRefreshClick, hasRefreshButton,
}:CustomErrorProps) {
  return (
    <Flex alignItems="center">
      <Box>
        <Text fontWeight="bold" fontSize="60px" color="red.500" pl={10}>
          {title}
        </Text>
        <Flex flexDirection="column" pt={3} pl={20}>
          <Text fontWeight="bold" fontSize="30px" color="black" maxW="600px">
            {description}
          </Text>
          {hasRefreshButton && (
            <Button
              onClick={onRefreshClick}
              mt={5}
              color="white"
              bgColor="#0061FF"
              width="50%"
            >
              Click here to go back
            </Button>
          )}
        </Flex>
      </Box>
      <Box pt="5%" pl={40}>
        <Image src={ErrorImage} alt="Unauthorized Error" />
      </Box>
    </Flex>
  );
}
CustomErrorPage.defaultProps = defaultProps;
