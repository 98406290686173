import { AxiosError } from 'axios';
import {
  QueryClient, UseQueryOptions, UseMutationOptions, DefaultOptions,
} from 'react-query';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          window.location.href = '/auth/login';
        }
      }
    },
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
ReturnType<FnType>
>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
'queryKey' | 'queryFn'
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
ExtractFnReturnType<MutationFnType>,
AxiosError,
Parameters<MutationFnType>[0]
>;
