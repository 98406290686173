import {
  Box, Flex, Heading, Image, Stack, Text,
} from '@chakra-ui/react';
import LoginForm from '@features/auth/components/LoginForm';
import { useNavigate } from 'react-router-dom';
import gmcTeam from '@assets/images/gmcTeam.jpg';
import Hotjar from '@hotjar/browser';
import logoGMC from '@assets/images/logo.png';

export const Login = function Login() {
  const navigate = useNavigate();
  const siteId = 3613944;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);

  const handleLoginSuccess = (role: string) => {
    switch (role) {
      case 'SuperAdmin':
        navigate('/app/campanies/dashboard');
        break;
      case 'CompanyOwner':
        navigate('/app/campanies/companyDashboard/');
        break;
      case 'HiringManager':
        navigate('/app/campanies/tests/');
        break;
      default:
        navigate('/app/profile');
        break;
    }
  };

  return (
    <Flex color="white" h="100vh" direction={{ base: 'column', md: 'row' }}>
      <Box
        flex={{ base: '1', md: '0.5' }}
        ml={{ base: '0', md: 150 }}
        pr={{ base: '0', md: 10 }}
        order={{ base: '2', md: '1' }}
      >
        <Stack m={100} mt={{ base: 20, md: 200 }}>
          <Heading as="h3" size="lg" color="black" mb={6} height="34px">
            Sign in to your account.
          </Heading>
          <Text fontSize="md" color="Grey">
            Unlock the power of efficient hiring and build the dream team
          </Text>
          <Text fontSize="md" color="Grey">
            your company deserves.
          </Text>
          <Box pt={10}>
            <LoginForm onSuccess={handleLoginSuccess} />
          </Box>
        </Stack>
      </Box>
      <Box flex={{ base: '1', md: '0.5' }} order={{ base: '1', md: '2' }}>
        <Image
          src={gmcTeam}
          width="100%"
          h="90%"
          mt={{ base: 110, md: 45 }}
          mr={20}
          ml={{ base: 20, md: -10 }}
          borderRadius="56px 0px"
          objectFit="cover"
        />
        <Box position="fixed" top="20px" left="20px" zIndex="100">
          <Image src={logoGMC} />
        </Box>
      </Box>
    </Flex>

  );
};
