/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Button,
  Flex, Grid, GridItem, Image, Stack, Text, useMediaQuery,
} from '@chakra-ui/react';
import CandidateScore from '@features/landingPage/components/assets/images/tests/CandidateScore.png';
import illustrator from '@features/landingPage/components/assets/images/tests/illustrator.png';
import photoshop from '@features/landingPage/components/assets/images/tests/photoshop.png';
import html from '@features/landingPage/components/assets/images/tests/html.png';
import python from '@features/landingPage/components/assets/images/tests/python.png';
import excel from '@features/landingPage/components/assets/images/tests/excel.png';
import word from '@features/landingPage/components/assets/images/tests/word.png';
import css from '@features/landingPage/components/assets/images/tests/css.png';
import javascript from '@features/landingPage/components/assets/images/tests/javascript.png';
import macbook from '@features/landingPage/components/assets/images/tests/macbook.png';
import { useEffect, useRef, useState } from 'react';
import PlatformQuestionsAccordion from './PlatformQuestionsAccordion';

export default function Tests(props: { onGetNextComponentClick: () => void }) {
  const showText = useRef(null);
  const showTextRef2 = useRef(null);
  const showCard = useRef(null);
  const image1 = useRef(null);
  const image2 = useRef(null);
  const image3 = useRef(null);
  const image4 = useRef(null);
  const image5 = useRef(null);
  const image6 = useRef(null);
  const image7 = useRef(null);
  const image8 = useRef(null);
  const image9 = useRef(null);
  const image10 = useRef(null);
  const buttonLearn = useRef(null);

  const [showText1, setShowText] = useState(false);
  const [showText2, setShowText2] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [showImage1, setShowImage1] = useState(false);
  const [showImage2, setShowImage2] = useState(false);
  const [showImage3, setShowImage3] = useState(false);
  const [showImage4, setShowImage4] = useState(false);
  const [showImage5, setShowImage5] = useState(false);
  const [showImage6, setShowImage6] = useState(false);
  const [showImage7, setShowImage7] = useState(false);
  const [showImage8, setShowImage8] = useState(false);
  const [showImage9, setShowImage9] = useState(false);
  const [showImage10, setShowImage10] = useState(false);
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            switch (entry.target.id) {
              case 'showText':
                setShowText(true);
                break;
              case 'image1':
                setShowImage1(true);
                break;
              case 'image2':
                setShowImage2(true);
                break;
              case 'image3':
                setShowImage3(true);
                break;
              case 'image4':
                setShowImage4(true);
                break;
              case 'image5':
                setShowImage5(true);
                break;
              case 'image6':
                setShowImage6(true);
                break;
              case 'image7':
                setShowImage7(true);
                break;
              case 'image8':
                setShowImage8(true);
                break;
              case 'image9':
                setShowImage9(true);
                break;
              case 'image10':
                setShowImage10(true);
                break;
              case 'showText2':
                setShowText2(true);
                break;
              case 'showCard':
                setShowCards(true);
                break;
              case 'button':
                setShowButton(true);
                break;
              default:
                break;
            }
          }
        });
      },
      { threshold: 0.5 },
    );

    if (image1.current) {
      observer.observe(image1.current);
    }
    if (image2.current) {
      observer.observe(image2.current);
    }
    if (image3.current) {
      observer.observe(image3.current);
    }
    if (image4.current) {
      observer.observe(image4.current);
    }
    if (image5.current) {
      observer.observe(image5.current);
    }
    if (image6.current) {
      observer.observe(image6.current);
    }
    if (image7.current) {
      observer.observe(image7.current);
    }
    if (image8.current) {
      observer.observe(image8.current);
    }
    if (image9.current) {
      observer.observe(image9.current);
    }
    if (buttonLearn.current) {
      observer.observe(buttonLearn.current);
    }
    if (showText.current) {
      observer.observe(showText.current);
    }
    if (showTextRef2.current) {
      observer.observe(showTextRef2.current);
    }
    if (showCard.current) {
      observer.observe(showCard.current);
    }
    if (image10.current) {
      observer.observe(image10.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);
  const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');
  return (
    <Flex
      height="100%"
      minWidth="100vw"
      justifyContent="center"
    >
      <Stack>
        <Box
          id="showText"
          ref={showText}
          opacity={showText1 ? 1 : 0}
          transform={showText1 ? 'translateY(0)' : 'translateY(70px)'}
          transition="opacity 0.5s ease-out, transform 0.5s ease-out"
        >
          <Flex justifyContent="center" flexDirection="column" alignItems="center">
            <Text
              fontSize={['11', '13', '16', '20', '28', '31']}
              fontWeight="700"
              color="#000000"
              mt={130}
              mb={4}
              maxWidth={['30%', '100%', '100%', '100%', '100%']}
              ml={['7', '10', '100', '30', '0']}
              mr={['10', '10', '0']}
            >
              Different Tests For Different Needs
            </Text>
            <Text
              fontSize={['9px', '11px', '12px', '14px']}
              color="#555555"
              ml={['10', '10', '10', '10', '0']}
              mr={['0', '0', '10', '0']}
              maxWidth={['20%', '25%', '100%', '100%', '100%', '100%']}
            >
              We Understand That The Testing Process Can Be Time-Consuming And Resource-Intensive.
              That&apos;s Why We&apos;ve Designed Our Platform
            </Text>

            <Text
              fontSize={['9', '11', '12', '14']}
              color="#555555"
              ml={['10', '10', '10', '10', '0']}
              maxWidth={['20%', '25%', '100%', '100%', '100%', '100%']}

            >
              To Be As User-Friendly As Possible,
              With A Range Of Customizable Tests And Assessment Tools That Are Quick And Easy To Set Up.
            </Text>
          </Flex>
        </Box>
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Flex flexDirection="row" mt={14} mb={8}>
            <Box
              borderRadius="13px"
              border="1px solid #EBECF0"
              width={['80px', '100px', '140px', '210px']}
              h={isSmallerThan768 ? '40px' : '66px'}
              display="flex"
              alignItems="center"
              id="image1"
              ref={image1}
              opacity={showImage1 ? 1 : 0}
              transform={showImage1 ? 'translateY(0)' : 'translateY(70px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
              ml={['-3', '10', '-10', '0', '0']}
            >
              {showImage1 && <Image src={illustrator} w={isSmallerThan768 ? '30px' : ''} /> }
              <Text fontSize={['10', '12', '14', '16']}>Adobe Illustrator</Text>
            </Box>
            <Box
              borderRadius="13px"
              border="1px solid #EBECF0"
              width={['85px', '100px', '140px', '210px']}
              h={isSmallerThan768 ? '40px' : '66px'}
              display="flex"
              alignItems="center"
              ml={isSmallerThan768 ? '2' : '20'}
              id="image2"
              ref={image2}
              opacity={showImage2 ? 1 : 0}
              transform={showImage2 ? 'translateY(0)' : 'translateY(70px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            >
              {showImage2 && <Image src={photoshop} w={isSmallerThan768 ? '30px' : ''} /> }
              <Text fontSize={['10', '12', '14', '16']}>Adobe Photoshop</Text>
            </Box>
          </Flex>
          <Box
            id="image3"
            ref={image3}
            opacity={showImage3 ? 1 : 0}
            transform={showImage3 ? 'translateY(0)' : 'translateY(70px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            w={isSmallerThan768 ? '170px' : ''}
            ml={['12', '10', '-5', '1', '0', '0']}
          >
            {showImage3 && <Image src={CandidateScore} maxWidth={{ base: '80%', md: '100%', lg: '100%' }} /> }

          </Box>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Box
            borderRadius="13px"
            border="1px solid #EBECF0"
            width={['70px', '70px', '120px', '210px']}
            h={isSmallerThan768 ? '40px' : '66px'}
            display="flex"
            alignItems="center"
            mr="600px"
            ml={['400', '360', '50', '10', '10', '0', '0']}
            mt={isSmallerThan768 ? '-90px' : '-130px'}
            id="image4"
            ref={image4}
            opacity={showImage4 ? 1 : 0}
            transform={showImage4 ? 'translateY(0)' : 'translateY(70px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            {showImage4 && <Image src={html} w={isSmallerThan768 ? '30px' : ''} />}
            <Text fontSize={['10', '12', '14', '16']}>HTML</Text>
          </Box>
          <Box
            borderRadius="13px"
            border="1px solid #EBECF0"
            width={['80px', '80px', '120px', '210px']}
            h={isSmallerThan768 ? '40px' : '66px'}
            display="flex"
            alignItems="center"
            mr="700px"
            mt={4}
            id="image5"
            ref={image5}
            opacity={showImage5 ? 1 : 0}
            transform={showImage5 ? 'translateY(0)' : 'translateY(70px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            ml={['490', '450', '20', '20', '0', '0']}
          >
            {showImage5 && <Image src={python} mr={2} w={['30px', '20px', '35px', '30px', '48px']} />}

            <Text fontSize={['10', '12', '14', '16']}>Python</Text>
          </Box>
        </Flex>
        <Flex flexDirection="row" alignItems="center" justifyContent="center">
          <Box
            borderRadius="13px"
            border="1px solid #EBECF0"
            width={['80px', '80px', '120px', '210px']}
            h={isSmallerThan768 ? '40px' : '66px'}
            display="flex"
            alignItems="center"
            id="image6"
            ref={image6}
            opacity={showImage6 ? 1 : 0}
            transform={showImage6 ? 'translateY(0)' : 'translateY(70px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
            ml={['5', '-50', '-30', '-10', '0', '0']}
          >
            {showImage6 && <Image src={excel} w={isSmallerThan768 ? '30px' : ''} />}

            <Text fontSize={['10', '12', '14', '16']}>Excel</Text>
          </Box>
          <Box
            borderRadius="13px"
            border="1px solid #EBECF0"
            width={['80px', '80px', '120px', '210px']}
            h={isSmallerThan768 ? '40px' : '66px'}
            display="flex"
            alignItems="center"
            ml={isSmallerThan768 ? '50' : '20'}
            mt={8}
            id="image7"
            ref={image7}
            opacity={showImage7 ? 1 : 0}
            transform={showImage7 ? 'translateY(0)' : 'translateY(70px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            {showImage7 && <Image src={word} mr={2} w={isSmallerThan768 ? '30px' : ''} />}
            <Text fontSize={['10', '12', '14', '16']}>Word</Text>
          </Box>
        </Flex>
        <Flex flexDirection="row" alignItems="center" justifyContent="center">
          <Box
            borderRadius="13px"
            border="1px solid #EBECF0"
            h={isSmallerThan768 ? '40px' : '66px'}
            width={['60px', '80px', '120px', '210px']}
            display="flex"
            alignItems="center"
            ml={['220', '240', '550', '650', '750', '700', '700']}
            mt={isSmallerThan768 ? '-280' : '-450px'}
            mb={2}
            id="image8"
            ref={image8}
            opacity={showImage8 ? 1 : 0}
            transform={showImage8 ? 'translateY(0)' : 'translateY(70px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            {showImage8 && <Image src={css} w={['30px', '35px', '30px', '48px']} height="30px" />}
            <Text fontSize={['10', '12', '14', '16']}>CSS</Text>
          </Box>
          <Box
            borderRadius="13px"
            border="1px solid #EBECF0"
            width={['90px', '100px', '130px', '210px']}
            h={isSmallerThan768 ? '40px' : '66px'}
            display="flex"
            alignItems="center"
            mt={isSmallerThan768 ? '-160' : '-300px'}
            ml={['-90', '-90', '-50', '-300']}
            id="image9"
            ref={image9}
            opacity={showImage9 ? 1 : 0}
            transform={showImage9 ? 'translateY(0)' : 'translateY(70px)'}
            transition="opacity 0.5s ease-out, transform 0.5s ease-out"
          >
            {showImage9 && <Image src={javascript} mr={2} w={isSmallerThan768 ? '25px' : ''} h={isSmallerThan768 ? '25px' : ''} />}

            <Text fontSize={['10', '12', '14', '16']}>Javascript</Text>
          </Box>
        </Flex>
        <Box
          id="button"
          ref={buttonLearn}
          opacity={showButton ? 1 : 0}
          transform={showButton ? 'translateY(0)' : 'translateY(70px)'}
          transition="opacity 0.5s ease-out, transform 0.5s ease-out"
        >
          <Flex flexDirection="row" alignItems="center" justifyContent="center">
            <Button
              fontSize="14px"
              fontWeight="400"
              color="white"
              bgColor="#176FFF"
              borderRadius="42px"
              width="168px"
              height="60px"
              ml={['15', '15', '0', '0']}
              onClick={props.onGetNextComponentClick}
            >Learn More
            </Button>
          </Flex>
        </Box>
        <Box
          id="showText2"
          ref={showTextRef2}
          opacity={showText2 ? 1 : 0}
          transform={showText2 ? 'translateY(0)' : 'translateY(70px)'}
          transition="opacity 0.5s ease-out, transform 0.5s ease-out"
        >
          <Flex justifyContent="center" flexDirection="column" alignItems="center">
            <Text
              // fontSize={isSmallerThan768 ? '16px' : '31px'}
              fontSize={['11', '13', '16', '20', '28', '31']}
              fontWeight="700"
              color="#000000"
              mt={isSmallerThan768 ? '100' : '200'}
              mb={4}
              ml={['5', '10', '0', '0']}
            >Answers To Your Questions
            </Text>
            <Text
              // fontSize={isSmallerThan768 ? '9px' : '14px'}
              fontSize={['9', '11', '14']}
              color="#555555"
              ml={['5', '10', '0', '0']}
              w={isSmallerThan768 ? '300px' : ''}
            >We have compiled a list of frequently asked questions to make
              it easy for you to find the answers you need.
            </Text>
          </Flex>
        </Box>
        <Box>
          <Grid templateColumns="repeat(12, 1fr)" marginY={10} marginX={20} gap={10}>
            <GridItem
              colSpan={7}
              id="showCard"
              ref={showCard}
              opacity={showCards ? 1 : 0}
              transform={showCards ? 'translateX(50px)' : 'translateX(0)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
              mt={isSmallerThan768 ? '250' : ''}
              ml={['420', '420', '0', '0']}
            >
              <PlatformQuestionsAccordion />
            </GridItem>
            <GridItem
              colSpan={5}
              id="image10"
              ref={image10}
              opacity={showImage10 ? 1 : 0}
              transform={showImage10 ? 'translateX(0)' : 'translateX(50px)'}
              transition="opacity 0.5s ease-out, transform 0.5s ease-out"
              position="sticky"
              right="0"
              marginRight="20px"
            >
              <Image
                src={macbook}
                ml={['20', '50', '50', '200', '200']}
                maxWidth={{ base: '100%', md: '100%', lg: '100%' }}
                h={isSmallerThan768 ? '210px' : ''}
              />
            </GridItem>
          </Grid>
        </Box>
      </Stack>
    </Flex>
  );
}
