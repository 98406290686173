import axios from '@lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query';
import { useQuery } from 'react-query';
import { AssignmentDTO } from '@features/candidateTest/types';
// TODO: change the type once api is on
export const getTestAndCandidate = (UniqueTestId: string): Promise<AssignmentDTO> => axios.get(`/CandidateTest/GetTestInfo/${UniqueTestId}`);

type QueryFnType = typeof getTestAndCandidate;

type UseTestAndCandidateOptions = {
  UniqueTestId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useTestAndCandidate = ({ UniqueTestId, config }: UseTestAndCandidateOptions) => useQuery<ExtractFnReturnType<QueryFnType>>({
  ...config,
  queryKey: ['testInfo', UniqueTestId],
  queryFn: () => getTestAndCandidate(UniqueTestId),
});
