import axios from '@lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@lib/react-query';
import { useQuery } from 'react-query';
import { UserDTO } from '../types/User';

export const getUserById = ():Promise<UserDTO> => axios.get('/users/info/');

type QueryFnType = typeof getUserById;

type UseUserOptions = {

  config?: QueryConfig<QueryFnType>;
};

export const useUser = ({ config } : UseUserOptions = {}) => useQuery<ExtractFnReturnType<QueryFnType>>({
  ...config,
  queryKey: ['user'],
  queryFn: () => getUserById(),
});
