import React, { useState } from 'react';
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Center,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

export default function TestCasesConsoleAccordion({ visibleTestCases, testResponses }:
{ visibleTestCases: { [key: string]: any }, testResponses: { [key: string]: string } }) {
  const [tabContentVisibility, setTabContentVisibility] = useState(false);

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      mb="100px"
      backgroundColor="#151517"
      border="1px"
      borderColor="#252525"
      paddingInline="20px"
      w="832px"
    >
      <Tabs width="100%" variant="unstyled">
        <TabList>
          <Tab
            fontSize="10px"
            fontWeight="500"
            lineHeight="18px"
            color="#545454"
            _selected={{
              color: tabContentVisibility ? '#DDDDDD' : '#545454',
              borderBottom: tabContentVisibility ? '1px' : 'none',
              borderColor: '#A5A5A5',
            }}
          >
            Test cases
          </Tab>
          <Tab
            fontSize="10px"
            fontWeight="500"
            lineHeight="18px"
            color="#545454"
            _selected={{
              color: tabContentVisibility ? '#DDDDDD' : '#545454',
              borderBottom: tabContentVisibility ? '1px' : 'none',
              borderColor: '#A5A5A5',
            }}
          >
            Output
          </Tab>
        </TabList>
        {tabContentVisibility && (
        <TabPanels width="100%">
          <TabPanel>
            <Flex height="250px" width="100%" flexDirection="column">
              {Object.entries(visibleTestCases).map(([key, value], index) => {
                const isAccepted = testResponses[key] === 'Accepted';
                const backgroundColor = isAccepted ? '#27352E' : '#352728';
                const textColor = isAccepted ? '#009F66' : '#AF4045';

                return (
                  <Flex
                    backgroundColor={backgroundColor}
                    border="1px"
                    borderColor="#252525"
                    width="100%"
                    height="39px"
                    alignItems="center"
                    marginBottom="15px"
                    key={key}
                  >
                    <Center width="39px" height="39px" borderRight="1px" borderColor="#252525">
                      <Text color={textColor} fontSize="14px" fontWeight="500" lineHeight="18px">
                        {index}
                      </Text>
                    </Center>
                    <Text
                      color={textColor}
                      fontSize="12px"
                      fontWeight="500"
                      lineHeight="18px"
                      marginLeft="15px"
                    >
                      Expected: {atob(value)} / Output: {testResponses[key] || ''}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex height="250px">
              {/* Render the test responses in the console */}
              {Object.entries(testResponses).map(([key, response], index) => (
                <Text
                  key={key}
                  color="white"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="18px"
                >
                  Test Case {index + 1}: {response}
                </Text>
              ))}
            </Flex>
          </TabPanel>
        </TabPanels>
        )}
      </Tabs>
      {tabContentVisibility ? (
        <ChevronUpIcon color="white" marginTop="10px" onClick={() => setTabContentVisibility(false)} />
      ) : (
        <ChevronDownIcon color="white" marginTop="10px" onClick={() => setTabContentVisibility(true)} />
      )}
    </Flex>
  );
}
