import axios from '@lib/axios';
import { MutationConfig } from '@lib/react-query';
import { useMutation } from 'react-query';
import { TestCodeDTO } from '../types';

export const testCode = (data : TestCodeDTO): Promise<any> => axios.post('/Coding/TestCode', data);

type UseTestCodeOptions = {
  config?: MutationConfig<typeof testCode>;
};
export const useTestCode = ({ config }: UseTestCodeOptions = {}) => useMutation(
  {
    ...config,
    mutationFn: testCode,
  },
);
