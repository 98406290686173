// send a post request to the server to alert it that the page has changed
import axios from '@lib/axios';
import { useMutation } from 'react-query';
import { MutationConfig } from '@lib/react-query';

const postAlertOnPageChange = (uniqueTestId: string | undefined): Promise<any> => axios.post(`/CandidateTest/WindowChangeDetected/${uniqueTestId}`);

type UsePostAlertOnPageChangeOptions = {
  UniqueTestId: string | undefined;
  wasHidden: boolean;
  config?: MutationConfig<typeof postAlertOnPageChange>;
};

export const usePostAlertOnPageChange = ({ UniqueTestId, wasHidden, config }: UsePostAlertOnPageChangeOptions) => useMutation({
  mutationFn: () => (wasHidden ? postAlertOnPageChange(UniqueTestId) : Promise.resolve()),
  ...config,
});
