import {
  Tag, TagLabel, TagLeftIcon, TagRightIcon,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

type PropTypes = {
  label: string;
  leftIcon?: IconType;
  rightIcon?: IconType;
  colorScheme?: string;
  color?: string;
  [x:string] : any;
};

export default function TagWithIcon({
  label, colorScheme, rightIcon, leftIcon, color, ...rest
} : PropTypes) {
  return (
    <Tag colorScheme={colorScheme} color={color} p={0} {...rest}>
      {leftIcon && <TagLeftIcon as={leftIcon} boxSize="22px" />}
      <TagLabel>{label}</TagLabel>
      {rightIcon && <TagRightIcon as={rightIcon} boxSize="22px" />}
    </Tag>
  );
}
TagWithIcon.defaultProps = {
  rightIcon: null,
  leftIcon: null,
  color: '#828A92',
  colorScheme: 'white',
};
