import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  CardHeader, Card, Image, CardBody, Text, Flex, CardFooter, Stack,
} from '@chakra-ui/react';
import LabIcon from '@assets/images/LabIcon.png';
import { AssignmentDTO } from '@features/candidateTest/types';
import { formatToHHMMSS } from '@utils/format';
import { CgTimer } from 'react-icons/cg';
import TagWithIcon from '@components/TagWithIcon';
import { BsFillQuestionOctagonFill } from 'react-icons/bs';
import { useMemo } from 'react';
import CustomButton from '@features/candidateTest/components/Buttons/CustomButton';
import { MdOutlineChecklist } from 'react-icons/md';
import nameSpaces from '@/i18n/nameSpaces';
import { useTranslation } from 'react-i18next';
const calculateNumberOfQuestions = (assignment:AssignmentDTO) => {
  let total = 0;
  for (let i = 0; i < assignment.assignedTest.test.assessments.length; i += 1) {
    total += assignment.assignedTest.test.assessments[i].numberOfQuestions;
  }
  return total;
};
type PropTypes = {
  toggleRulesPage: () => void;
};
export default function TestInfoCard({ toggleRulesPage } : PropTypes) {
  const { UniqueTestId } = useParams();
  const queryClient = useQueryClient();
  const assignment : AssignmentDTO = queryClient.getQueryData(['testInfo', UniqueTestId])!;
  const numberOfQuestions = useMemo(() => calculateNumberOfQuestions(assignment), [assignment]);
  const { t } = useTranslation(nameSpaces.candidateInstructions);
  let totalDuration = 0;
  if (assignment && assignment?.assignedTest && assignment?.assignedTest?.test) {
    const assessments = assignment?.assignedTest?.test?.assessments || [];
    assessments?.forEach((assessment) => {
      totalDuration += assessment?.duration;
    });
  }
  return (
    <Card rounded="2xl">
      <CardHeader>
        <Image src={LabIcon} />
      </CardHeader>
      <CardBody>
        <Stack gap={10}>
          <Flex flexDirection="column" gap={5}>
            <Text
              fontSize="2xl"
              fontWeight="bold"
              textShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              textTransform="capitalize"
            > {assignment.assignedTest.test.title}
            </Text>
            <Text
              fontSize="md"
              color="#828A92"
            >{assignment.description}
            </Text>
          </Flex>
          <Flex gap={10}>
            <TagWithIcon
              label={t('testCardInfo.totalDuration', {duration: `${formatToHHMMSS(totalDuration)}`})}
              leftIcon={CgTimer}
              color="#6D6B6B"
            />
            <TagWithIcon
              label={t('testCardInfo.testParts', {parts: `${assignment.assignedTest.test.assessments.length}`})}
              leftIcon={MdOutlineChecklist}
              color="#6D6B6B"
            />
            <TagWithIcon
              label={t('testCardInfo.totalQuestions', {total: `${numberOfQuestions}`})}
              leftIcon={BsFillQuestionOctagonFill}
              color="#6D6B6B"

            />
          </Flex>
        </Stack>

      </CardBody>
      <CardFooter>
        <CustomButton label={t('testCardInfo.startButton')} variant="solidIcon" onClick={toggleRulesPage} />
      </CardFooter>

    </Card>
  );
}
