/* eslint-disable import/no-extraneous-dependencies */
import {
  Box,
  Flex,
  useColorModeValue, Image, IconButton, useDisclosure, useMediaQuery, Collapse,
} from '@chakra-ui/react';
import { Link } from 'react-scroll';
import logoGMC from '@assets/images/logo.png';

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import MobileNavbar from './MobileNavbar/MobileNavbar';
import DesktopNavbar from './DesktopNavbar/DesktopNavbar';

function BurgerMenu({ onClick, open }: { onClick: () => void; open: boolean }) {
  return (
    <Box position="fixed" top={5} left={0} zIndex={1000}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        aria-label="Toggle Navigation"
        icon={
          open ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
        }
        onClick={onClick}
        variant="ghost"
      />
    </Box>
  );
}
export default function Navbar({ onNavClick, activeComponent }: { onNavClick: (component: string) => void; activeComponent: string }) {
  const { isOpen, onToggle } = useDisclosure();
  const [activeLink] = useState(activeComponent);
  const [isSmallerThan768] = useMediaQuery('(max-width: 765px)');

  const handleClick = (component: string) => {
    onNavClick(component);
    onToggle();
  };

  return (
    <Flex
      bg={useColorModeValue('white', 'gray.800')}
      height="100%"
      minWidth="100%"
      justifyContent="center"
      overflow="hidden"
    >

      <BurgerMenu onClick={onToggle} open={isOpen} />
      {!isSmallerThan768 ? (
        <DesktopNavbar onNavClick={handleClick} activeComponent={activeLink} />
      ) : (
        <Flex>
          <Link to="home" smooth duration={500} offset={-60}>
            <Image src={logoGMC} mt={{ base: 1, md: 7 }} alt="logoGMC" />
          </Link>
          <Collapse in={isOpen} animateOpacity>
            <MobileNavbar onNavClick={handleClick} activeComponent={activeLink} />
          </Collapse>
        </Flex>
      )}
    </Flex>

  );
}
