import { MutationConfig } from '@lib/react-query';
import { useMutation } from 'react-query';
import { useSignIn } from 'react-auth-kit';
import { UserResponse } from '../types';
import axios from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

async function handleUserLogin(data: UserResponse, signIn:any) {
  const { token, user } = data;
  const JWT_VALIDITY_LENGTH = 1440; // 31 days
  return signIn(
    {
      token,
      expiresIn: JWT_VALIDITY_LENGTH,
      tokenType: 'Bearer',
      authState: user,
    },
  );
}
export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => axios.post('login', data);

type UseLoginOptions = {
  config?: MutationConfig<typeof loginWithEmailAndPassword>;
};

export const useLogin = ({ config }: UseLoginOptions = {}) => {
  const signIn = useSignIn();
  const { addNotification } = useNotificationStore();
  return useMutation({
    ...config,
    onSuccess: (data: UserResponse) => {
      handleUserLogin(data, signIn);
      addNotification({
        type: 'success',
        title: 'Welcome back!',
      });
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 401) {
        window.location.href = '/auth/login';
      }
    },
    mutationFn: loginWithEmailAndPassword,
  });
};
