import React from 'react';
import AppRoutes from '@routes/AppRoutes';
import AppProvider from '@providers/app';
import './i18n/config';

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
