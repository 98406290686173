/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export function useCopyPaste(callbackFn: (copyPasteCount: number) => void, openModal: () => void) {
  const [copyPasteCount, setCopyPasteCount] = useState(0);

  const handleExitCopyPaste = () => {
    setCopyPasteCount((count) => count + 1);
    openModal();
    callbackFn(copyPasteCount + 1);
  };

  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      event.preventDefault();
      handleExitCopyPaste();
    };

    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleExitCopyPaste();
    };

    const handlePasteAnywhere = (event: Event) => {
      const clipboardEvent = event as ClipboardEvent;
      if (clipboardEvent.clipboardData) {
        clipboardEvent.clipboardData.getData('text/plain');
        event.preventDefault();
        event.stopPropagation();
        openModal();
      }
    };

    window.addEventListener('copy', handleCopy as EventListener);
    window.addEventListener('paste', handlePaste as EventListener);

    if ('ClipboardEvent' in window) {
      window.addEventListener('paste', handlePasteAnywhere as EventListener);
    }

    return () => {
      window.removeEventListener('copy', handleCopy as EventListener);
      window.removeEventListener('paste', handlePaste as EventListener);
      if ('ClipboardEvent' in window) {
        window.removeEventListener('paste', handlePasteAnywhere as EventListener);
      }
    };
  }, [callbackFn, openModal]);

  return copyPasteCount;
}
